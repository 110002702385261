<template>
       <div>
        <div class="pd-ltr-20">
           <div class="faq-wrap">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <img src="/images/banner-img.png" alt="">
                                    </div>
                                    <div class="col-md-8">
                                        <h4 class="font-20 weight-500 mb-10 text-capitalize">
                                            Olá, <div class="weight-600 font-30 text-blue">{{  User.nome }}</div>
                                        </h4>
                                        <p class="font-18 max-width-100"> 
                                            <v-select v-model="AnoSelected" label="Selecione o Ano" :items="Anos" @update:modelValue="CarregaLista(AnoSelected)" style="width: 150px;"
                                                item-title="nome" item-value="valor" variant="underlined">
                                            </v-select>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="AnoSelected!=''">
                        <div class="col-xl-3 mb-30">
                            <div class="card-box height-100-p widget-style1">
                                <div class="d-flex flex-wrap align-items-center" v-if="ListClientes!=''">
                                    <div class="progress-data">
                                        <v-icon style="font-size: 45px;">mdi-human-queue</v-icon>
                                    </div>
                                    <div class="widget-data">
                                        <div class="h4 mb-0">{{ ListClientes.length }}</div>
                                        <div class="weight-600 font-14">Clientes Total</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 mb-30">
                            <div class="card-box height-100-p widget-style1">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="progress-data">
                                        <v-icon style="font-size: 45px;">mdi-diamond-stone</v-icon>
                                    </div>
                                    <div class="widget-data">
                                        <div class="h4 mb-0">{{ListProdutosGeral.length }}</div>
                                        <div class="weight-600 font-14">Produtos Total</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 mb-30">
                            <div class="card-box height-100-p widget-style1">
                                <div class="d-flex flex-wrap align-items-center" v-if="ListClientesNovos!=''">
                                    <div class="progress-data">
                                        <v-icon style="font-size: 45px;">mdi-human-queue</v-icon>
                                    </div>
                                    <div class="widget-data">
                                        <div class="h4 mb-0">{{  ListClientesNovos.length }}</div>
                                        <div class="weight-600 font-14">Clientes Novos</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <div class="col-xl-3 mb-30">
                            <div class="card-box height-100-p widget-style1">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="progress-data">
                                        <v-icon style="font-size: 45px;">mdi-store</v-icon>
                                    </div>
                                    <div class="widget-data">
                                        <div class="h4 mb-0">{{ ListPedidos.length }}</div>
                                        <div class="weight-600 font-14">Qtde Pedidos</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="ListaProdutos!=''">
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Produtos Mais Vendidos</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(mais,index) in ListaProdutos.slice(0, 10)" :key="index">
                                            <div class="browser-name">{{mais.pdiNomeProduto}}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">{{mais.totalQuantidade}}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Produtos Menos Vendidos</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(menos,index) in ListaProdutos.slice(-10)" :key="index">
                                            <div class="browser-name">{{menos.pdiNomeProduto}}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">{{menos.totalQuantidade}}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Produtos Não Vendidos</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(menos,index) in produtosNaoVendidos.slice(0,14)" :key="index" style="margin-bottom: 5px !important ">
                                            <div class="browser-name" style="font-size: 12px;">{{menos.proNomeProduto}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Onde Conheceu</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(item, index) in contagemPorItemOrdenada" :key="index" style="margin-bottom: 5px !important ">
                                            <div class="browser-name" style="width: calc(100% - 20px) !important;font-size: 12px;"> {{ item.item  }}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">{{ item.quantidade }}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Pedidos por Mês R$</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(pedmes,index) in PedidosMes" :key="index">
                                            <div class="browser-name" style="width: calc(100% - 100px) !important;"> {{ pedmes.mes }}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">R$ {{ formatarValor(pedmes.soma) }}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Pedidos por Mês Qtde</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(pedmes,index) in PedidosQtdeMes" :key="index">
                                            <div class="browser-name" style="width: calc(100% - 100px) !important;"> {{ pedmes.mes }}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">{{ pedmes.soma }}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Pedidos Tipo Pgto</h2>
                                <div class="browser-visits">
                                    <ul>
                                        <li class="d-flex flex-wrap align-items-center" v-for="(pedmes,index) in PedidosTipoMes" :key="index">
                                            <div class="browser-name" style="width: calc(100% - 100px) !important;"> {{ pedmes.mes }}</div>
                                            <div class="visit"><span class="badge badge-pill badge-primary">{{ formatarValor(pedmes.soma) }}</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-12 mb-30">
                            <div class="card-box pd-30 pt-10 height-100-p">
                                <h2 class="mb-30 h4">Gráfico Vendas</h2>
                                <Bar v-if="re_render" id="my-chart-id" :options="chartOptions" :data="chartData" />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'HomeView',
  components: {
    moment,
    Bar 
  },
  data: () => ({
    accordion: false,
    ListClientes:[],
    ListClientesNovos:[],
    ListProdutosGeral:[],
    ListPedidosGeral:[],
    ListPedidos:[],
    ListPedidositem:[],
    ListaProdutos:[],
    PedidosMes:[],
    PedidosQtdeMes:[],
    PedidosTipoMes:[],
    produtosNaoVendidos:[],
    contagemPorItemOrdenada:[],
    AnoSelected:'',
    Anos:[
        {nome: '2023',valor: 2023},
        {nome: '2024',valor: 2024},
        {nome: '2025',valor: 2025},
        {nome: '2026',valor: 2026},
        {nome: '2027',valor: 2027},
        {nome: '2028',valor: 2028},
        {nome: '2029',valor: 2029},
        {nome: '2030',valor: 2030},
    ],
    chartData: {
        labels: [],
        datasets: [ { label: 'Valores',
                    backgroundColor: '#364f66',
                    data: [] } ]
      },
      chartOptions: {
        responsive: true
      },
      re_render: true,
  }),
  methods: {
    async forcedReRender() {
   this.re_render = false;
},
    CarregaLista(AnoSelected){
        this.ListClientesNovos = this.ListClientes.filter(x=> this.formattedDateYear(x.cliDataCadastro) == AnoSelected)
        this.ListPedidos = this.ListPedidosGeral.filter(x=> this.formattedDateYear(x.pedDataPedido) == AnoSelected)
        var filtraProdutos = this.ListPedidositem.filter(x=> this.formattedDateYear(x.pdiDataCadastro) == AnoSelected)
        this.ListaProdutos = this.produtosMaisVendidos(filtraProdutos); 
        this.produtosNaoVendidos = this.ListProdutosGeral.filter(item => !filtraProdutos.map(elemento => elemento.proId).includes(item.proId));
        this.calcularSomasPorMes();
        this.calcularqtdePorMes();
        this.calculartipoPorMes();     

        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];

        this.PedidosMes.forEach(item => {
           this.chartData.labels.push(item.mes);
           this.chartData.datasets[0].data.push(item.soma)
        });  

        const contagemPorItem = {};

        // Iterar sobre o array e contar as ocorrências de cada item
        this.ListClientesNovos.forEach(item => {
            if (item.cliCampoExtra2!='' && item.cliCampoExtra2!=null){
                const campoExtra2 = item.cliCampoExtra2;
                if (contagemPorItem[campoExtra2]) {
                    contagemPorItem[campoExtra2]++;
                } else {
                    contagemPorItem[campoExtra2] = 1;
                }
            }
        });

        // Converter o objeto em um array de pares chave-valor
        const arrayContagem = Object.entries(contagemPorItem);

        // Ordenar o array com base na quantidade (valor) em ordem descendente
        arrayContagem.sort((a, b) => b[1] - a[1]);

        // Mapear o array ordenado para o formato desejado
        const itensOrdenados = arrayContagem.map(([item, quantidade]) => ({ item, quantidade }));

        this.contagemPorItemOrdenada = itensOrdenados;

        this.forcedReRender().then(()=>{
           this.re_render = true;
        });

        
    },
    calcularSomasPorMes() {
      const somasPorMes = {};

      // Iterar sobre os pedidos
      this.ListPedidos.forEach(pedido => {
        const dataPedido = moment(pedido.pedDataPedido);
        const mesNome = dataPedido.format('MMMM');

        // Verificar se o mês já existe no objeto de somas
        if (!somasPorMes[mesNome]) {
          somasPorMes[mesNome] = 0;
        }

        // Adicionar o valor do pedido ao total do mês
        somasPorMes[mesNome] += parseFloat(pedido.pedValorTotalPedido); // Certificar-se de tratar o valor como número
      });

      // Converter o objeto em um array de objetos
      const somasPorMesArray = Object.keys(somasPorMes).map(mes => ({
        mes: mes,
        soma: somasPorMes[mes],
      }));

      // Definir a propriedade PedidosMes no escopo do componente
      this.PedidosMes = somasPorMesArray;
    },
    calcularqtdePorMes() {
      const QtdePorMes = {};

      // Iterar sobre os pedidos
      this.ListPedidos.forEach(pedido => {
        const dataPedido = moment(pedido.pedDataPedido);
        const mesNome = dataPedido.format('MMMM');

        // Verificar se o mês já existe no objeto de somas
        if (!QtdePorMes[mesNome]) {
            QtdePorMes[mesNome] = 0;
        }

        // Adicionar o valor do pedido ao total do mês
        QtdePorMes[mesNome] += 1; // Certificar-se de tratar o valor como número
      });

      // Converter o objeto em um array de objetos
      const somasPorMesArray = Object.keys(QtdePorMes).map(mes => ({
        mes: mes,
        soma: QtdePorMes[mes],
      }));

      // Definir a propriedade PedidosMes no escopo do componente
      this.PedidosQtdeMes = somasPorMesArray;
    },
    calculartipoPorMes() {
      const QtdePorMes = {};

      // Iterar sobre os pedidos
      this.ListPedidos.forEach(pedido => {
        const mesNome = pedido.pedFormaPgto;

        // Verificar se o mês já existe no objeto de somas
        if (!QtdePorMes[mesNome]) {
            QtdePorMes[mesNome] = 0;
        }

        // Adicionar o valor do pedido ao total do mês
        QtdePorMes[mesNome] += parseFloat(pedido.pedValorTotalPedido); // Certificar-se de tratar o valor como número
      });

      // Converter o objeto em um array de objetos
      const somasPorMesArray = Object.keys(QtdePorMes).map(mes => ({
        mes: mes,
        soma: QtdePorMes[mes],
      }));

      // Definir a propriedade PedidosMes no escopo do componente
      this.PedidosTipoMes = somasPorMesArray;
    },
     formattedDate (value) {
        return this.$moment(value).format('DD/MM/YYYY')
    },
    formattedDateYear (value) {
        return this.$moment(value).format('YYYY')
    },
    formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
    produtosMaisVendidos(value) {
      // Agrupar por pdiNomeProduto e somar as quantidades
      const groupedByProductName = value.reduce((acc, obj) => {
        const pdiNomeProduto = obj.pdiNomeProduto;
        acc[pdiNomeProduto] = (acc[pdiNomeProduto] || 0) + obj.pdiQtdeProduto;
        return acc;
      }, {});

      // Converter para array de objetos com nome do produto e quantidade total
      const produtosComQuantidades = Object.keys(groupedByProductName).map(pdiNomeProduto => ({
        pdiNomeProduto: pdiNomeProduto,
        totalQuantidade: groupedByProductName[pdiNomeProduto],
      }));

      // Ordenar por totalQuantidade em ordem decrescente
      produtosComQuantidades.sort((a, b) => b.totalQuantidade - a.totalQuantidade);

      return produtosComQuantidades;

    },
  },
  created() {
    const valorArmazenado = localStorage.getItem('_userav');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);
    }

        axios.get(`/Process/CliClientes/GetAll`)
        .then(response => {
            this.ListClientes = response.data;
        });


    axios.get(`/Process/ProProdutos/GetAll`) 
        .then(response => {
            this.ListProdutosGeral = response.data;                        
        });

        axios.get(`/Process/PedPedidos/GetAllValorTotal`) //GetAllByCtiId/14
        .then(response => {
            this.ListPedidosGeral = response.data.filter(x=> x.idiId == 1 && x.pedSituacaoPedido != "Cancelado");   
            console.log('this.ListPedidosGeral')
            console.log(this.ListPedidosGeral)  
            
        });

        axios.get(`/Process/PedPedidoItem/GetAll`) //GetAllByCtiId/14
        .then(response => {
            this.ListPedidositem = response.data;  
            console.log('ListPedidositem')
            console.log(this.ListPedidositem)       
        });


  },
}
</script>

<style>

</style>
