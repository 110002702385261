<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Segmentos Institucional</h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <v-tab v-if="this.CamposTela.isaSegmentoImg && SegmentoInst.sgiId!=0" value="Imagens" class="tabinfo">Imagens</v-tab>
                        <v-tab v-if="this.CamposTela.isaSegmentoArq && SegmentoInst.sgiId!=0" value="Arquivos" class="tabinfo">Arquivos</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="SegmentoInst.sgiId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="SegmentoInst.gtiId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="SegmentoInst.ctiId" label="Selecione a Tela" :items="Telas" @update:modelValue="CarregaCampos(SegmentoInst.ctiId)"
                                                item-title="ctiNome" item-value="ctiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="SegmentoInst.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista()"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="CamposTela.sgiSegmento">
                                            <v-select v-model="SegmentoInst.sgiIdPai" label="Seg Pai" :items="ListaSegmentosPai"
                                                item-title="sgiNomeSegmento" item-value="sgiId" variant="underlined" :clearable="true">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" v-if="CamposTela.sgiNomeSegmento" :md="CamposTela.sgiNomeSegmentoCol">
                                            <v-text-field v-model="SegmentoInst.sgiNomeSegmento"
                                                :label="CamposTela.sgiNomeSegmentoLabel" variant="underlined" required></v-text-field>
                                        </v-col>

                                        <v-col cols="12" v-if="CamposTela.sgiOrdem" :md="CamposTela.sgiOrdemCol">
                                            <v-text-field v-model="SegmentoInst.sgiOrdem" :label="CamposTela.sgiOrdemLabel" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" v-if="CamposTela.sgiVisivel" :md="CamposTela.sgiVisivelCol">
                                            <v-select v-model="SegmentoInst.sgiVisivel" :label="CamposTela.sgiVisivelLabel" :items="StatusSegmento"
                                                item-title="nome" item-value="sgiVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="CamposTela.sgiDestaque" cols="12" :md="CamposTela.sgiDestaqueCol">
                                            <v-select v-model="SegmentoInst.sgiDestaque" :label="CamposTela.sgiDestaqueLabel" :items="StatusDestaque"
                                                item-title="nome" item-value="sgiDestaque" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="CamposTela.sgiResumo" cols="12" :md="CamposTela.sgiResumoCol">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.sgiResumoLabel }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoInst.sgiResumo" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.sgiDescricao1" cols="12" :md="CamposTela.sgiDescricao1Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.sgiDescricao1Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoInst.sgiDescricao1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.sgiDescricao2" cols="12" :md="CamposTela.sgiDescricao2Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.sgiDescricao2Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoInst.sgiDescricao2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="Imagens" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de Imagens </div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="6">
                                        <v-file-input v-model="selectedFileImg" multiple label="Imagens" variant="underlined"
                                            prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddImagens(SegmentoInst.sgiId)">
                                            Carregar Imagens
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container v-if="ListaSegImgs!=''">
                                        <v-row v-for="arqs in ListaSegImgs" :key="arqs.isgId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="arqs.isgId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" md="12" style="text-align:center;">
                                                    <v-img :src="$imgURL + arqs.isgUrlImagem" variant="underlined"></v-img>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="arqs.isgNome" label="Nome" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="arqs.isgAlt" label="Alt" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-text-field v-model="arqs.isgOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-select v-model="arqs.isgVisivel" label="Visível" :items="StatusImgVisivel"
                                                        item-title="nome" item-value="isgVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <small style="padding-bottom:5px;"><strong>Texto</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="arqs.isgResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn icon @click="SaveImagens(arqs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteImagens(arqs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="Arquivos">
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de Arquivos </div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFileArq" multiple label="Arquivos" variant="underlined"
                                            prepend-icon="mdi-file-upload-outline" accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <button type="button" class="btn btn-success" @click="AddArquivos(SegmentoInst.sgiId)">
                                            Carregar Arquivos
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container v-if="ListaSegArqs!=''">
                                        <v-row v-for="arqs in ListaSegArqs" :key="arqs.isaId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="arqs.isaId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" md="12" style="text-align:center;">
                                                    <a :href="$imgURL + arqs.isaUrlArquivo" target="_blank">
                                                        <v-icon style="font-size:60px;">mdi-file-eye-outline</v-icon><br>
                                                        <span style="font-size:8px;width: 100%;">{{ arqs.isaUrlArquivo }}</span>
                                                    </a>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="arqs.isaNome" label="Arquivo" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="arqs.isaOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-select v-model="arqs.isaVisivel" label="Visível" :items="StatusArqVisivel"
                                                        item-title="nome" item-value="isaVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <small style="padding-bottom:5px;"><strong>Texto</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="arqs.isaResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn icon @click="SaveArquivos(arqs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteArquivos(arqs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                    </v-window>
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar(true)" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="SegmentoInst.sgiId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListSegmentosInst)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                       
                        <template #item-sgiVisivel="item">
                            <span>{{ item.sgiVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-sgiDestaque="item">
                            <span>{{ item.sgiDestaque ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-sgiOrdem="item">
                            <span>{{ parseInt(item.sgiOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
export default {
    name: 'SegmentosInstView',
    components: {
        EasyDataTable
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        ListaSegImgs:[],
        StatusImgVisivel: [{ nome: "Sim", isgVisivel: true }, { nome: "Não", isgVisivel: false }],
        selectedFileImg: null,
        ImagemSeg:{},
        ListaSegArqs:[],
        StatusArqVisivel: [{ nome: "Sim", isaVisivel: true }, { nome: "Não", isaVisivel: false }],
        selectedFileArq: null,
        ArquivoSeg:{},
        Idiomas:[],
        vshowidioma: false,
        CamposTela:[],
        Telas:[],
        SegmentoInst: {
            sgiId: 0,
            gtiId: 0,
            ctiId: '',
            idiId: '',
            sgiNomeSegmento: '',
            sgiVisivel: '',
            sgiDestaque: '',
            sgiOrdem: '',
            sgiResumo: '',
            sgiDescricao1: '',
            sgiDescricao2: '',
            sgiIdPai:'',
        },
        selectedVisivel: null,
        StatusSegmento: [{ nome: "Sim", sgiVisivel: true }, { nome: "Não", sgiVisivel: false }],
        StatusDestaque:  [{ nome: "Sim", sgiDestaque: true }, { nome: "Não", sgiDestaque: false }],
        headers: [],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        ListSegmentosInst: [],
        ListSegmentosInstGeral:[],
        ListaSegmentosPai:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                sgiOrdem: parseInt(item.sgiOrdem)
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        Limpar(value) {
            this.SegmentoInst.sgiId = 0;
            this.SegmentoInst.sgiNomeSegmento = '';
            this.SegmentoInst.sgiOrdem = '';
            this.SegmentoInst.sgiVisivel = '';
            this.SegmentoInst.sgiDestaque = '';
            this.SegmentoInst.sgiResumo = '';
            this.SegmentoInst.sgiDescricao1 = '';
            this.SegmentoInst.sgiDescricao2 = '';
            this.SegmentoInst.sgiIdPai = '';
            this.selectedVisivel = '';
            if (value){
                this.SegmentoInst.gtiId=0;
                this.SegmentoInst.ctiId='';
                this.SegmentoInst.idiId='';
                this.vshowidioma = false;
               this.CamposTela = [];
               this.ListSegmentosInst = [];
               this.Get();
            }
            this.ListaSegImgs = [];
            this.ListaSegArqs = [];
            this.tab = 'dados';
            this.$scrollToTop();
        },
        CarregaCampos(value) {
            axios.get(`/Process/AdmConfigTelaInstitucional/GetByCtiId/${value}`)
                .then(response => {
                    /*console.log('response.data')
                    console.log(response.data)*/
                    if (response.data != '') {
                        this.CamposTela = response.data;
                        axios.get(`/Process/CadIdiomas/GetAll`)
                            .then(response => {
                                this.Idiomas = response.data.filter(x => x.idiAtivo);
                                if (this.Idiomas.length == 1) {
                                    this.SegmentoInst.idiId = this.Idiomas[0].idiId;
                                    this.vshowidioma = true;
                                }
                                else{
                                    this.vshowidioma = true;
                                }
                            });
                            this.CarregaLista();
                           

                            axios.get(`Process/AdmCadastroTelasInst/GetById/${value}`)
                                        .then(response => {
                                            this.CadastroTelas = response.data;
                                            if (this.CadastroTelas != '') {
                                                this.SegmentoInst.ctiId = this.CadastroTelas.ctiId;
                                                this.SegmentoInst.gtiId = this.CadastroTelas.admGrupoTelasInst.gtiId;                                               
                                            }
                                        });
                    }
                });
        },
        CarregaLista(){
            axios.get(`/Process/InsSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosInstGeral = response.data;
                                    this.montaGrid();
                                    this.ListaSegmentosPai = this.ListSegmentosInstGeral.filter(x=> x.sgiVisivel && x.ctiId == this.SegmentoInst.ctiId && x.idiId == this.SegmentoInst.idiId);
           this.ListSegmentosInst = this.ListSegmentosInstGeral.filter(x=> x.ctiId == this.SegmentoInst.ctiId && x.idiId == this.SegmentoInst.idiId);
        //------ carrega select sgiIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.sgiIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.sgiNomeSegmento : `${caminhoAtual} > ${segmentoFilho.sgiNomeSegmento}`;

                segmentosComCaminho.push({ sgiId: segmentoFilho.sgiId, sgiNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.sgiId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaSegmentosPai.filter(x=> x.sgiIdPai== 0)) {
            segmentosComCaminho.push({ sgiId: segmento.sgiId, sgiNomeSegmento: segmento.sgiNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaSegmentosPai, segmento.sgiId, segmento.sgiNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaSegmentosPai = segmentosComCaminho;   
            this.ListSegmentosInst =  this.itensComHierarquia(this.ListSegmentosInst);  
        //------
        });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "sgiId", sortable: true, });
            this.headers.push({ text: "Hierarquia", value: "Hierarquia", sortable: true});
            if (this.CamposTela.sgiNomeSegmento) {
                this.headers.push({ text: this.CamposTela.sgiNomeSegmentoLabel, value: "sgiNomeSegmento", sortable: true, });
            }
            if (this.CamposTela.sgiOrdem) {
                this.headers.push({ text: this.CamposTela.sgiOrdemLabel, value: "sgiOrdem", sortable: true, });
            }
            if (this.CamposTela.sgiVisivel) {
                this.headers.push({ text: this.CamposTela.sgiVisivelLabel, value: "sgiVisivel", sortable: true, });
            }
            if (this.CamposTela.sgiDestaque) {
                this.headers.push({ text: this.CamposTela.sgiDestaqueLabel, value: "sgiDestaque", sortable: true, });
            }
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaSegmentosPai.filter(
            (x) => x.sgiId === item.sgiIdPai
            )[0]?.sgiNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        Add() {
            if (this.SegmentoInst.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.SegmentoInst.sgiNomeSegmento == '' && this.CamposTela.sgiNomeSegmento) {
                this.$mensagemAviso("Campo " + this.CamposTela.sgiNomeSegmentoLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiOrdem === '' && this.CamposTela.sgiOrdem){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiOrdemLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiVisivel === '' && this.CamposTela.sgiVisivel){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiVisivelLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiDestaque === '' && this.CamposTela.sgiDestaque){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiDestaqueLabel + " Obrigatório.")
            }
            else {
                if (this.SegmentoInst.sgiVisivel === "" || this.SegmentoInst.sgiVisivel === null) {
                    this.SegmentoInst.sgiVisivel = true;
                }
                if (this.SegmentoInst.sgiDestaque === "" || this.SegmentoInst.sgiDestaque === null) {
                        this.SegmentoInst.sgiDestaque = false;
                }
                if (this.SegmentoInst.sgiOrdem === "" || this.SegmentoInst.sgiOrdem === null) {
                    this.SegmentoInst.sgiOrdem = 0;
                }
                else {
                    this.SegmentoInst.sgiOrdem = parseInt(this.SegmentoInst.sgiOrdem);
                }
                this.SegmentoInst.admGrupoTelasInst = { GtiId: this.SegmentoInst.gtiId }
                this.SegmentoInst.admCadastroTelasInst = { CtiId: this.SegmentoInst.ctiId }
                this.SegmentoInst.cadIdiomas = { IdiId: this.SegmentoInst.idiId }
                if (this.SegmentoInst.sgiIdPai!='' && this.SegmentoInst.sgiIdPai!=null)
                {
                    this.SegmentoInst.insSegmentoPai = {SgiId: this.SegmentoInst.sgiIdPai}
                }
                
                axios.post(`/Process/InsSegmentos/Add`, this.SegmentoInst)
                    .then(response => {
                        if (response.data > 0) {
                            if (this.CamposTela.isaSegmentoImg || this.CamposTela.isaSegmentoArq) {
                                this.SegmentoInst.sgiId = response.data;
                                this.CarregaLista();
                                this.$mensagemAvisoSucesso("Registro Incluído. Abas complementares liberadas.")
                            }
                            else {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaLista();
                                this.Limpar(false);
                            }
                        }
                    });
            }
        },
        Put() {
            if (this.SegmentoInst.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.SegmentoInst.sgiNomeSegmento == '' && this.CamposTela.sgiNomeSegmento) {
                this.$mensagemAviso("Campo " + this.CamposTela.sgiNomeSegmentoLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiOrdem === '' && this.CamposTela.sgiOrdem){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiOrdemLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiVisivel === '' && this.CamposTela.sgiVisivel){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiVisivelLabel + " Obrigatório.")
            }
            else if (this.SegmentoInst.sgiDestaque === '' && this.CamposTela.sgiDestaque){
                this.$mensagemAviso("Campo " + this.CamposTela.sgiDestaqueLabel + " Obrigatório.")
            }
            else {
                if (this.SegmentoInst.sgiVisivel === "" || this.SegmentoInst.sgiVisivel === null) {
                    this.SegmentoInst.sgiVisivel = true;
                }
                if (this.SegmentoInst.sgiDestaque === "" || this.SegmentoInst.sgiDestaque === null) {
                        this.SegmentoInst.sgiDestaque = false;
                }
                if (this.SegmentoInst.sgiOrdem === "" || this.SegmentoInst.sgiOrdem === null) {
                    this.SegmentoInst.sgiOrdem = 0;
                }
                else {
                    this.SegmentoInst.sgiOrdem = parseInt(this.SegmentoInst.sgiOrdem);
                }
                this.SegmentoInst.admGrupoTelasInst = { GtiId: this.SegmentoInst.gtiId }
                this.SegmentoInst.admCadastroTelasInst = { CtiId: this.SegmentoInst.ctiId }
                this.SegmentoInst.cadIdiomas = { IdiId: this.SegmentoInst.idiId }
                if (this.SegmentoInst.sgiIdPai!='' && this.SegmentoInst.sgiIdPai!=null)
                {
                    this.SegmentoInst.insSegmentoPai = {SgiId: this.SegmentoInst.sgiIdPai}
                }
                axios.put(`/Process/InsSegmentos/Update`, this.SegmentoInst)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaLista();
                            this.Limpar(false);
                        }
                    });
            }
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item
            console.log('item')
            console.log(item)
            if (item.sgiIdPai == 0){
                item.sgiIdPai = null;
            }
            this.SegmentoInst = item;
          
            if (this.CamposTela.isaSegmentoImg){
                this.CarregaImagens(item.sgiId);
           }

           if (this.CamposTela.isaSegmentoArq){
              this.CarregaArquivos(item.sgiId);
           }
           


            this.$scrollToTop();
        },
        DeleteGrid(item) {
           
            var validaFilhos = this.ListSegmentosInst.filter(x=> x.sgiIdPai == item.sgiId).length;

            if (validaFilhos > 0){
                this.$mensagemAviso("Exclusão negada, esse item possui filhos.")
            }
            else{

                axios.get(`/Process/InsInstitucionalSegmentos/GetAll`)
                    .then(response => {
                        this.ValidaSeg = response.data.filter(x=> x.insSegmentos.sgiId == item.sgiId)

                        if (this.ValidaSeg.length > 0){
                            this.$mensagemAviso("Exclusão negada, registro em uso em outras telas.")
                        }
                        else{
                            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                            .then((result) => {
                            if (result.isConfirmed) {
                                // Lógica para excluir o item
                                const param1 = item.sgiId;
                                axios.delete(`/Process/InsSegmentos/Delete/${param1}`)
                                .then(response => {
                                    if (response.status == 200) {
                                        this.$mensagemAvisoSucesso("Registro Excluido.")
                                        this.CarregaLista();
                                        this.Limpar(false);
                                    }
                                });
                }
                });
                        }
                    });             
            }
           
        },
        async AddArquivos(value){           
            if (this.selectedFileArq != '' && this.selectedFileArq != null) {
                let selected3Promises = this.selectedFileArq.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ArquivoSeg.isaUrlArquivo = uploadSuccess.data.nameFile;
                                this.ArquivoSeg.isaVisivel = true;
                                this.ArquivoSeg.isaResumo = "";
                                this.ArquivoSeg.insSegmentos = { SgiId: value };
                                return axios.post(`/Process/InsSegmentosArquivos/Add`, this.ArquivoSeg);
                            } else {
                                this.$mensagemErro("Não foi possível carregar arquivo.");
                                return null;
                            }
                    });

                    Promise.all(selected3Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaArquivos(value);
                            this.selectedFileArq = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Algum arquivo pode ter dado erro na carga." + error);
                        });
                }
                else{
                    this.$mensagemAviso("Selecione um arquivo.")
                }
        },
        CarregaArquivos(value){
              //carrega lista de Arquivos
              axios.get(`/Process/InsSegmentosArquivos/GetBySgiId/${value}`)
                    .then(response => {
                        if (response.data!="" && response.data!=null ){
                            this.ListaSegArqs = response.data.sort((a, b) => {
                                                                            const valorA = parseInt(a.isaOrdem) || 0;
                                                                            const valorB = parseInt(b.isaOrdem) || 0;
                                                                            return valorA - valorB;
                                                                        });
                        }
                        else{
                            this.ListaSegArqs = [];
                        }
                       
                    });

        },
        SaveArquivos(value){
            var _arqinfo = value;
            if (_arqinfo.isaOrdem === "" || _arqinfo.isaOrdem === null) {
                _arqinfo.isaOrdem = 0;
            }
            else {
                _arqinfo.isaOrdem = parseInt(_arqinfo.isaOrdem);
            }
            
            axios.put(`/Process/InsSegmentosArquivos/Update`, _arqinfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaArquivos(this.SegmentoInst.sgiId);
                        }
                    });
        },
        DeleteArquivos(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.isaId;
                axios.delete(`/Process/InsSegmentosArquivos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaArquivos(this.SegmentoInst.sgiId);
                    }
                });
            }
            });
        },
        async AddImagens(value){           
            if (this.selectedFileImg != '' && this.selectedFileImg != null) {
                let selected3Promises = this.selectedFileImg.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ImagemSeg.isgUrlImagem = uploadSuccess.data.nameFile;
                                this.ImagemSeg.isgVisivel = true;
                                this.ImagemSeg.isgResumo = "";
                                this.ImagemSeg.insSegmentos = { SgiId: value };
                                return axios.post(`/Process/InsSegmentosImagens/Add`, this.ImagemSeg);
                            } else {
                                this.$mensagemErro("Não foi possível carregar arquivo.");
                                return null;
                            }
                    });

                    Promise.all(selected3Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaImagens(value);
                            this.selectedFileImg = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Algum arquivo pode ter dado erro na carga." + error);
                        });
                }
                else{
                    this.$mensagemAviso("Selecione um arquivo.")
                }
        },
        CarregaImagens(value){
                //carrega imagens
                axios.get(`/Process/InsSegmentosImagens/GetBySgiId/${value}`)
                    .then(response => {
                        this.ListaSegImgs = response.data.sort((a, b) => {
                            const valorA = parseInt(a.isgOrdem) || 0;
                            const valorB = parseInt(b.isgOrdem) || 0;
                            return valorA - valorB;
                        });

                        console.log('this.ListaSegImgs')
                        console.log(this.ListaSegImgs)
                    });
        },
        SaveImagens(value){
            var _arqinfo = value;
            if (_arqinfo.isgOrdem === "" || _arqinfo.isgOrdem === null) {
                _arqinfo.isgOrdem = 0;
            }
            else {
                _arqinfo.isgOrdem = parseInt(_arqinfo.isgOrdem);
            }
            
            axios.put(`/Process/InsSegmentosImagens/Update`, _arqinfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaImagens(this.SegmentoInst.sgiId);
                        }
                    });
        },
        DeleteImagens(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.isgId;
                axios.delete(`/Process/InsSegmentosImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaImagens(this.SegmentoInst.sgiId);
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/AdmCadastroTelasInst/GetTelasInstSegmentos`)
                        .then(response => {
                            this.Telas = response.data;
                    });
}
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
