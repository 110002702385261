<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Clientes.cliId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Clientes.cctId" label="Tipo" :items="TipoClientes"
                                                item-title="cctNome" item-value="cctId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="Clientes.cliNomeEmpresa"
                                                label="Nome" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Clientes.cliVisivel" label="Visível" :items="StatusVisivel"
                                                item-title="nome" item-value="cliVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display: none;">
                                            <v-select v-model="Clientes.cliAtivo" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="cliAtivo" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Clientes.cliDataCadastro" type="date" :disabled="Clientes.cliId !== 0"
                                                label="Cadastro" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" style="display: none;">
                                            <v-text-field v-model="Clientes.cliTelefone" v-mask="'(##) ####-#####'"
                                                label="Telefone" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="selectedPais" label="País (DDI)" :items="Paises" @update:modelValue="Clientes.cliWhatsapp=''"
                                                item-title="cpaNome" item-value="cpaId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Clientes.cliWhatsapp"  ref="whatsAppInput"
                                                label="Whatsapp" variant="underlined" required></v-text-field>
                                                <span class="small-text">*Número com DDD (ex: 5199999999)</span>
                                        </v-col>
                                     
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Clientes.cliEmail" label="E-mail" 
                                            variant="underlined" @blur="validateEmail"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Clientes.cliSenha" :type="senhaVisivel ? 'text' : 'password'"
                                                label="Senha" variant="underlined"
                                                :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                                                @click:append="toggleSenhaVisivel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Clientes.cctId == 1">
                                            <v-text-field v-model="Clientes.cliDataNascimento" type="date"
                                                label="Data Nascimento" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 1">
                                            <v-text-field v-model="Clientes.cliCpf" v-mask="'###.###.###-##'"
                                                label="CPF" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliCnpj" v-mask="'##.###.###/####-##'"
                                                label="CNPJ" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliRazaoSocial"
                                                label="Razão Social" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliResponsavel" 
                                                label="Responsável" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display: none;">
                                            <small style="padding-bottom:5px;"><strong>Descrição 1</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Clientes.cliTexto1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display: none;">
                                            <small style="padding-bottom:5px;"><strong>Descrição 2</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Clientes.cliTexto2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display: none;">
                                            <small style="padding-bottom:5px;"><strong>Descrição 3</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Clientes.cliTexto3" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra1"
                                                label="Campo Extra 1" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Clientes.cliCampoExtra2"
                                                label="Onde Conheceu" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra3"
                                                label="Campo Extra 3" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra4"
                                                label="Campo Extra 4" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra5"
                                                label="Campo Extra 5" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData1" type="date"
                                                label="Data 1" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData2" type="date"
                                                label="Data 2" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Clientes.cliId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListClientes" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cliVisivel="item">
                            <span>{{ item.cliVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cliDataCadastro="item">
                            <span>{{ item.cliDataCadastro!=null? formattedDate(item.cliDataCadastro) : null }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'
import { SHA256 } from 'crypto-js';
import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export default {
    name: 'CliClientesView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Cadastro de Clientes',
        InfoTela:'Lista de clientes',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 17, cdtNome: "Cadastro Clientes" }],
        //dados
        Clientes: {
            cliId: 0,
            cctId: "",
            cliVisivel: '',
            cliDataCadastro: '',
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            cliCnpj: '',
            cliCpf: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliSenha: '',
            cliAtivo: '',
            cliDataNascimento: '',
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: '',
            cliData2: '',
        },
        ListClientes: [],
        StatusVisivel: [{ nome: "Sim", cliVisivel: true }, { nome: "Não", cliVisivel: false }],
        StatusAtivo: [{ nome: "Sim", cliAtivo: true }, { nome: "Não", cliAtivo: false }],
        //dados
        TipoClientes:[],
        senhaVisivel: false,
        isEmailValid:false,
        emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
      ],
      Paises:[],
    selectedPais:null,
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
        validarNumero() {
    // Remover espaços em branco e caracteres desnecessários
        let numeroTelefone = this.Clientes.cliWhatsapp.trim();

      try {
        // Parse do número de telefone
        const parsedNumber = phoneUtil.parse(numeroTelefone, 'ZZ');
        
        // Verificar se o número é válido
        if (phoneUtil.isValidNumber(parsedNumber)) {
          this.aplicarMascara(); // Formatar o número de telefone
        } else {
          this.$mensagemAviso('Número de telefone inválido');
          this.Clientes.cliWhatsapp = ''; // Limpar o campo
          this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
        }
      } catch (e) {
        console.error('Erro ao processar o número de telefone:', e);
        this.Clientes.cliWhatsapp = ''; // Limpar o campo
        this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
      }
        },
        aplicarMascara() {
            let input = this.Clientes.cliWhatsapp.replaceAll("(","").replaceAll(")","").replaceAll("-","");

            let fonePais = this.Paises.filter(x=> x.cpaId == this.selectedPais)[0];

            input =  "+" + fonePais.cpaFone + this.Clientes.cliWhatsapp;

            try {
                const parsedNumber = phoneUtil.parseAndKeepRawInput(input, 'ZZ');
                const isValid = phoneUtil.isValidNumber(parsedNumber);

                if (isValid) {
                const formattedNumber = phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
                this.Clientes.cliWhatsapp = formattedNumber;
                return true;
                } else {
                    this.Clientes.cliWhatsapp = ''; // Limpar o campo
                    return false;
                }
            } catch (e) {
                this.Clientes.cliWhatsapp = ''; // Limpar o campo
                return false;
            }
        },
        Limpar() {
            this.Clientes.cliId = 0;
            this.Clientes.cctId= "";
            this.Clientes.cliVisivel="";
            this.Clientes.cliDataCadastro="";
            this.Clientes.cliNomeEmpresa="";
            this.Clientes.cliRazaoSocial="";
            this.Clientes.cliCnpj="";
            this.Clientes.cliCpf="";
            this.Clientes.cliTelefone="";
            this.Clientes.cliWhatsapp="";
            this.Clientes.cliResponsavel="";
            this.Clientes.cliEmail="";
            this.Clientes.cliSenha="";
            this.Clientes.cliAtivo="";
            this.Clientes.cliDataNascimento="";
            this.Clientes.cliCampoExtra1="";
            this.Clientes.cliCampoExtra2="";
            this.Clientes.cliCampoExtra3="";
            this.Clientes.cliCampoExtra4="";
            this.Clientes.cliCampoExtra5="";
            this.Clientes.cliTexto1="";
            this.Clientes.cliTexto2="";
            this.Clientes.cliTexto3="";
            this.Clientes.cliData1="";
            this.Clientes.cliData2="";
            this.tab = 'dados';
            this.Get();
        },
       async Add() {
                if (this.Clientes.cctId == '') {
                    this.$mensagemAviso("Campo Tipo Cliente Obrigatório.")
                }
                else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                    this.$mensagemAviso("Campo Nome Cliente Obrigatório.")
                }
                else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                    this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
                }
                else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                    this.$mensagemAviso("Campo Razão Social Obrigatório.")
                }
                else if (this.Clientes.cliVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else if (this.Clientes.cliWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Clientes.cliEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Clientes.cliSenha === '') {
                    this.$mensagemAviso("Campo Senha Obrigatório.")
                }
                else {

                    let _result = await this.aplicarMascara();

                if (_result)
                {
                    if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                        this.Clientes.cliVisivel = true;
                    }
                    if (this.Clientes.cliAtivo === "" || this.Clientes.cliAtivo === null) {
                        this.Clientes.cliAtivo = true;
                    }
                    this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }
                    if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                        this.Clientes.cliDataNascimento = null;
                    }
                    else{
                        this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                        this.Clientes.cliData1 = null;
                    }
                    else{
                        this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                        this.Clientes.cliData2 = null;
                    }
                    else{
                        this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                        this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                    }

                    this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
                    axios.post(`/Process/CliClientes/Add`, this.Clientes)
                        .then(response => {
                            if (response.data > 0) {
                                  this.$mensagemAvisoSucesso("Registro Incluído.")
                                  this.Get();
                                  this.Limpar();
                                  this.tab = 'dados';                        
                            }
                        });
                }
                else{
                    this.$mensagemAviso('Número de whatsapp inválido');
                }
            }
        },
        async Put() {
            if (this.Clientes.cctId == '') {
                this.$mensagemAviso("Campo Tipo Cliente Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo Nome Cliente Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
            }
            else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Razão Social Obrigatório.")
            }
            else if (this.Clientes.cliVisivel === '') {
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else if (this.Clientes.cliWhatsapp === '') {
                this.$mensagemAviso("Campo Whatsapp Obrigatório.")
            }
            else if (this.Clientes.cliEmail === '') {
                this.$mensagemAviso("Campo E-mail Obrigatório.")
            }
            else if (this.Clientes.cliSenha === '') {
                this.$mensagemAviso("Campo Senha Obrigatório.")
            }
            else {

                let _result = await this.aplicarMascara();

            if (_result)
            {
                if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                        this.Clientes.cliVisivel = false;
                    }
                    this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }

                    if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                        this.Clientes.cliDataNascimento = null;
                    }
                    else{
                        this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                        this.Clientes.cliData1 = null;
                    }
                    else{
                        this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                        this.Clientes.cliData2 = null;
                    }
                    else{
                        this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                        this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliSenha != "" && this.Clientes.cliSenha != null){
                        this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
                    }

                axios.put(`/Process/CliClientes/Update`, this.Clientes)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
                }
                else{
                    this.$mensagemAviso('Número de whatsapp inválido');
                }
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cliId;
                axios.delete(`/Process/CliClientes/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item   
            if (item.cliDataCadastro != "" && item.cliDataCadastro != null) {
                item.cliDataCadastro = this.$moment(item.cliDataCadastro).format('YYYY-MM-DD')
            }
            else{
                item.cliDataCadastro = '';
            }
            if (item.cliDataNascimento != "" && item.cliDataNascimento != null) {
                item.cliDataNascimento = this.$moment(item.cliDataNascimento).format('YYYY-MM-DD')
            }
            else{
                item.cliDataNascimento = '';
            }
            if (item.cliData1 != "" && item.cliData1 != null) {
                item.cliData1 = this.$moment(item.cliData1).format('YYYY-MM-DD')
            }
            else{
                item.cliData1 = '';
            }
            if (item.cliData2 != "" && item.cliData2 != null) {
                item.cliData2 = this.$moment(item.cliData2).format('YYYY-MM-DD')
            }
            else{
                item.cliData2 = '';
            }

            item.cliSenha = null;
            this.Clientes = item;
            this.Clientes.cctId = item.cliClienteTipo.cctId; 
            axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.sort((a, b) => {
                                                            const nomeA = a.cpaNome || ""; 
                                                            const nomeB = b.cpaNome || ""; 
                                                            return nomeA.localeCompare(nomeB);
                                                            });
                                                            this.selectedPais = 76;
                                                        });
            
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "cliId", sortable: true, });
            this.headers.push({ text: "Nome", value: "cliNomeEmpresa", sortable: true, });
            this.headers.push({ text: "Visível", value: "cliVisivel", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "cliWhatsapp", sortable: true, });
            this.headers.push({ text: "E-mail", value: "cliEmail", sortable: true, });
            this.headers.push({ text: "Cadastro", value: "cliDataCadastro" });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CliClienteTipo/GetAll`)
                        .then(response => {
                            this.TipoClientes = response.data;                         
                        
                    });

                    axios.get(`/Process/CliClientes/GetAll`)
                        .then(response => {
                            this.ListClientes = response.data;                         
                        });
                    this.montaGrid();

                    axios.get(`/Process/CadPaises/GetAll`)
                    .then(response => {
                        this.Paises = response.data.sort((a, b) => {
                                                                const nomeA = a.cpaNome || ""; 
                                                                const nomeB = b.cpaNome || ""; 
                                                                return nomeA.localeCompare(nomeB);
                                                                });
               // console.log('this.Paises')
               // console.log(this.Paises)

                });
        }
    },
    async created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
