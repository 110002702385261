<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <v-tab v-if="MostraAba" value="bloco" class="tabinfo">{{BlocosHome.homTipoBloco.htbNomeBloco}}</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="BlocosHome.hbhId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="BlocosHome.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="BlocosHome.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="BlocosHome.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(BlocosHome.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="BlocosHome.htbId" label="Tipo Bloco" :items="TiposBlocosHome"
                                                item-title="htbNomeBloco" item-value="htbId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="BlocosHome.hbhNomeBloco"
                                                label="Nome Bloco" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="BlocosHome.hbhVisivel" label="Visível" :items="StatusAtivo"
                                                item-title="nome" item-value="hbhVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="BlocosHome.hbhOrdem" type="number"
                                                label="Ordem" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="mostraCampoExtra1">
                                            <v-text-field v-model="BlocosHome.hbhCampoExtra1" type="text"
                                                label="Texto Botão" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="mostraCampoExtra2">
                                            <v-text-field v-model="BlocosHome.hbhCampoExtra2" type="text"
                                                label="Link Botão" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" style="display: none;">
                                            <v-text-field v-model="BlocosHome.hbhCampoExtra3" type="text"
                                                label="Campo Extra 3" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" v-if="mostraCorFundo">
                                            <v-text-field v-model="BlocosHome.hbhCorFundo" type="color"
                                                label="Cor Fundo" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" v-if="mostraCorFonte">
                                            <v-text-field v-model="BlocosHome.hbhCorFonte" type="color"
                                                label="Cor Fonte" variant="underlined" required></v-text-field>
                                        </v-col>
                                      
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                         <!--Grids Por Bloco-->
                         <v-window-item value="bloco" >
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="BlocosHomeItens.hbiId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="BlocosHomeItens.hbhId" label="hbhId" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="9" v-if="mostraProdutos">
                                        <v-select v-model="BlocosHomeItens.proId" :label="LabelProdutos" :items="ListaProdutos"
                                            item-title="proNomeProduto" item-value="proId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="mostraImagem">
                                         <v-file-input v-model="selectedFile" :label="LabelImagens" variant="underlined"
                                            prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="4" v-if="mostraLink">
                                        <v-text-field v-model="BlocosHomeItens.hbiLink" :label="LabelLink" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" v-if="mostraTexto">
                                        <v-text-field v-model="BlocosHomeItens.hbiTexto" :label="LabelTexto" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2" v-if="mostraOrdem">
                                        <v-text-field v-model="BlocosHomeItens.hbiOrdem" :label="LabelOrdem" type="number" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2" v-if="mostraVisivel">
                                        <v-select v-model="BlocosHomeItens.hbiVisivel" :label="LabelVisivel" :items="StatusVisivel"
                                            item-title="nome" item-value="hbiVisivel" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddItensBloco()">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-form>
                                                <v-container>
                                                    <v-row v-for="imgs in ListaBlocosHomeItens" :key="imgs.hbiId">
                                                        <v-col cols="12" md="4" v-show="false">
                                                            <v-text-field v-model="imgs.hbiId" label="Id" variant="underlined"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="2" v-if="mostraImagem">
                                                            <v-col cols="12" md="12">
                                                                <v-img :src="$imgURL + imgs.hbiUrlImg" variant="underlined"></v-img>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12" md="2" v-if="mostraProdutos">
                                                            <v-col cols="12" md="12">
                                                                <v-img :src="$imgURL + imgs.proProdutos.proProdutosImagens[0].pimUrlImagem" variant="underlined"></v-img>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12" md="8">
                                                            <v-row>
                                                                <v-col cols="12" md="4" v-if="mostraProdutos">
                                                                    <v-text-field v-model="imgs.proProdutos.proReferencia" variant="underlined" disabled></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4" v-if="mostraProdutos">
                                                                    <v-text-field v-model="imgs.proProdutos.proNomeProduto" variant="underlined" disabled></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4" v-if="mostraLink">
                                                                    <v-text-field v-model="imgs.hbiLink" :label="LabelLink" variant="underlined"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4" v-if="mostraOrdem">
                                                                    <v-text-field v-model="imgs.hbiOrdem" :label="LabelOrdem" type="number" variant="underlined"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4" v-if="mostraVisivel">
                                                                    <v-select v-model="imgs.hbiVisivel" :label="LabelVisivel" :items="StatusVisivel"
                                                                        item-title="nome" item-value="hbiVisivel" variant="underlined">
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="12" v-if="mostraTexto">
                                                                    <v-text-field v-model="imgs.hbiTexto" :label="LabelTexto" variant="underlined"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-btn icon @click="SaveImages(imgs)" style="margin-right: 10px;" variant="text">
                                                                <v-icon>mdi-content-save-outline</v-icon>
                                                                <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                            </v-btn>

                                                            <v-btn icon @click.prevent="DeleteImages(imgs)" variant="text">
                                                                <v-icon>mdi-delete-forever-outline</v-icon>
                                                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="BlocosHome.hbhId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListBlocosHome" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-hbhVisivel="item">
                            <span>{{ item.hbhVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'HomBlocosHomeView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Blocos Home',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 30, cdtNome: "Blocos Home" }],
        //dados
        BlocosHome: {
            hbhId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            htbId:'',
            hbhNomeBloco: '',
            hbhVisivel: '',
            hbhOrdem: '',
            hbhCampoExtra1: '',
            hbhCampoExtra2: '',
            hbhCampoExtra3: '',
            hbhCorFundo: '',
            hbhCorFonte: '',
        },
        ListBlocosHome: [],
        StatusAtivo: [{ nome: "Sim", hbhVisivel: true }, { nome: "Não", hbhVisivel: false }],
        //dados
        ListBlocosHomeGeral:[],
        TiposBlocosHome:[],
        MostraAba: false,
        //blocoitens
        BlocosHomeItens: {
            hbiId: 0,
            hbhId: '',
            proId: '',
            hbiTexto: '',
            hbiLink:'',
            hbiVisivel: '',
            hbiOrdem: '',
            hbiUrlImg:'',
        },
        ListaBlocosHomeItens:[],
        ListaProdutos:[],
        StatusVisivel: [{ nome: "Sim", hbiVisivel: true }, { nome: "Não", hbiVisivel: false }],
        mostraProdutos:false,
        mostraTexto:false,
        mostraLink:false,
        mostraOrdem:false,
        mostraVisivel:false,
        mostraImagem:false,
        mostraCampoExtra1:false,
        mostraCampoExtra2:false,
        mostraCampoExtra3:false,
        mostraCorFundo:false,
        mostraCorFonte:false,
        LabelProdutos:'Produtos',
        LabelTexto:'',
        LabelLink:'',
        LabelOrdem:'Ordem',
        LabelVisivel:'Visível',
        LabelImagens:'Imagem',
        InfoImagem:'',
        selectedFile: null,
        //blocoitens
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        CarregaLista(){
           this.ListBlocosHome = this.ListBlocosHomeGeral.filter(x=> x.idiId == this.BlocosHome.idiId);
        },
        Limpar() {
            this.BlocosHome.hbhId=0;
            this.BlocosHome.grtId=0;
            this.BlocosHome.cdtId=0;
            this.BlocosHome.htbId="";
            this.BlocosHome.hbhNomeBloco="";
            this.BlocosHome.hbhVisivel="";
            this.BlocosHome.hbhOrdem="";
            this.BlocosHome.hbhCampoExtra1="";
            this.BlocosHome.hbhCampoExtra2="";
            this.BlocosHome.hbhCampoExtra3="";
            this.BlocosHome.hbhCorFundo="";
            this.BlocosHome.hbhCorFonte="";
            this.MostraAba = false;
            this.tab = 'dados';
            this.Get();
        },
        Add() {
               if (this.BlocosHome.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.BlocosHome.hbhNomeBloco == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.BlocosHome.hbhVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else if (this.BlocosHome.hbhOrdem === '') {
                    this.$mensagemAviso("Campo Ordem Obrigatório.")
                }
                else {
                    if (this.BlocosHome.hbhVisivel === "" || this.BlocosHome.hbhVisivel === null) {
                        this.BlocosHome.hbhVisivel = true;
                    }
                    if (this.BlocosHome.hbhOrdem === "" || this.BlocosHome.hbhOrdem === null) {
                        this.BlocosHome.hbhOrdem = 0;
                    }
                    else {
                        this.BlocosHome.hbhOrdem = parseInt(this.BlocosHome.hbhOrdem);
                    }
                 
                    this.BlocosHome.homTipoBloco = {htbId: this.BlocosHome.htbId, htbNomeBloco : this.TiposBlocosHome.filter(x=> x.htbId == this.BlocosHome.htbId)[0].htbNomeBloco}
                    axios.post(`/Process/HomBlocosHome/Add`, this.BlocosHome)
                        .then(response => {
                            if (response.data > 0) {
                                    this.BlocosHome.hbhId = response.data;
                                    this.Get();
                                    this.EditGrid(this.BlocosHome);
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas detalhes liberadas.")                      
                            }
                        });
            }
        },
        Put() {
            if (this.BlocosHome.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.BlocosHome.hbhNomeBloco == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.BlocosHome.hbhVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else if (this.BlocosHome.hbhOrdem === '') {
                    this.$mensagemAviso("Campo Ordem Obrigatório.")
                }
            else {
                if (this.BlocosHome.hbhVisivel === "" || this.BlocosHome.hbhVisivel === null) {
                        this.BlocosHome.hbhVisivel = true;
                    }
                    if (this.BlocosHome.hbhOrdem === "" || this.BlocosHome.hbhOrdem === null) {
                        this.BlocosHome.hbhOrdem = 0;
                    }
                    else {
                        this.BlocosHome.hbhOrdem = parseInt(this.BlocosHome.hbhOrdem);
                    }

             
                axios.put(`/Process/HomBlocosHome/Update`, this.BlocosHome)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.hbhId;
                axios.delete(`/Process/HomBlocosHome/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            this.BlocosHome = item;
            this.BlocosHome.htbId = item.homTipoBloco.htbId
            //carrega item do bloco
            this.BlocosHomeItens.hbhId = this.BlocosHome.hbhId
            axios.get(`/Process/HomBlocosHomeItens/GetAllByHbhId/${this.BlocosHome.hbhId}`)
                .then(response => {
                    this.ListaBlocosHomeItens = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.hbiOrdem) || 0;
                                                                                const valorB = parseInt(b.hbiOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });                
                });
            axios.get(`/Process/ProProdutos/GetAll`) 
                    .then(response => {
                        this.ListaProdutos = response.data.filter(x=> x.proVisivel).sort((a, b) => {
                                                                                    const nomeA = a.proNomeProduto || ""; 
                                                                                    const nomeB = b.proNomeProduto || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                        
                    });

            this.MostraAba = true;        

            if (this.BlocosHome.homTipoBloco.htbId == 1){
                
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = true;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 1920px x 400px";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;

            } else if (this.BlocosHome.homTipoBloco.htbId == 2)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = true;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "Tamanho ideal 300px x 100px";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;

            } else if (this.BlocosHome.homTipoBloco.htbId == 3)
            {
                this.mostraProdutos = true;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = true;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = true;
                this.mostraCampoExtra2 = true;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;

                
            } else if (this.BlocosHome.homTipoBloco.htbId == 4)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 1270px x 250px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 5)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 620px x 250px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 6)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 410px x 250px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 7)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 300px x 200px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 8)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 200px x 100px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 9)
            {
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = true;
                this.mostraOrdem = false;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "";
                this.LabelLink = "Link";
                this.InfoImagem = "Tamanho ideal 1920px x 120px ";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;

            } else if (this.BlocosHome.homTipoBloco.htbId == 10)
            {
                this.mostraProdutos = true;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = true;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 11)
            {
                this.mostraProdutos = true;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = true;
                this.mostraVisivel = true;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 12)
            {
                this.mostraProdutos = true;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = true;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 13)
            {
                this.mostraProdutos = true;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = true;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCorFonte = true;
                this.mostraCorFundo = true;
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 14)
            {
                this.mostraProdutos = false;
                this.mostraTexto = true;
                this.mostraLink = true;
                this.mostraOrdem = true;
                this.mostraVisivel = true;
                this.mostraImagem = true;
                this.LabelTexto = "Texto";
                this.LabelLink = "Título";
                this.InfoImagem = "Tamanho ideal 80px x 80px";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } else if (this.BlocosHome.homTipoBloco.htbId == 15)
            {
                this.MostraAba = false;
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = false;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } 
            else if (this.BlocosHome.homTipoBloco.htbId == 16)
            {
                this.MostraAba = false;
                this.mostraProdutos = false;
                this.mostraTexto = false;
                this.mostraLink = false;
                this.mostraOrdem = false;
                this.mostraVisivel = false;
                this.mostraImagem = false;
                this.LabelTexto = "";
                this.LabelLink = "";
                this.InfoImagem = "";
                this.mostraCampoExtra1 = false;
                this.mostraCampoExtra2 = false;
                this.mostraCorFonte = false;
                this.mostraCorFundo = false;
                
            } 

            //carrega item do bloco

            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "hbhId", sortable: true, });
            this.headers.push({ text: "Nome Bloco", value: "hbhNomeBloco", sortable: true, });
            this.headers.push({ text: "Visivel", value: "hbhVisivel", sortable: true, });
            this.headers.push({ text: "Ordem", value: "hbhOrdem", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        async AddItensBloco(){
   
            if (this.mostraImagem) {

                if (this.selectedFile != '' && this.selectedFile != null) {
                    let selected2Promises = this.selectedFile.map(async (element) => {
                        const uploadSuccess = await this.UploadImg(element);
                        if (uploadSuccess.status == 200) {
                            this.BlocosHomeItens.hbiUrlImg = uploadSuccess.data.nameFile;

                            if (this.BlocosHomeItens.hbiOrdem === "" || this.BlocosHomeItens.hbiOrdem === null) {
                                this.BlocosHomeItens.hbiOrdem = 0;
                            }
                            else {
                                this.BlocosHomeItens.hbiOrdem = parseInt(this.BlocosHomeItens.hbiOrdem);
                            }

                            if (this.BlocosHomeItens.proId === "" || this.BlocosHomeItens.proId === null) {
                                this.BlocosHomeItens.proId = 0;
                            }
                            else {
                                this.BlocosHomeItens.proId = parseInt(this.BlocosHomeItens.proId);
                            }

                            if (this.BlocosHomeItens.hbiVisivel === "" || this.BlocosHomeItens.hbiVisivel === null) {
                                this.BlocosHomeItens.hbiVisivel = true;
                            }

                            return axios.post(`/Process/HomBlocosHomeItens/Add`, this.BlocosHomeItens);
                        } else {
                            this.$mensagemErro("Não foi possível carregar a imagem.");
                            return null;
                        }
                    });

                    Promise.all(selected2Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaItens(this.BlocosHomeItens.hbhId);
                            this.LimpaInputimg();
                            this.selectedFile = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Alguma imagem pode ter dado erro na carga." + error);
                        });

                }
                else {
                    this.$mensagemAviso("Selecione a imagem.")
                }
            }
            else {

                if (this.BlocosHomeItens.hbiOrdem === "" || this.BlocosHomeItens.hbiOrdem === null) {
                    this.BlocosHomeItens.hbiOrdem = 0;
                }
                else {
                    this.BlocosHomeItens.hbiOrdem = parseInt(this.BlocosHomeItens.hbiOrdem);
                }

                if (this.BlocosHomeItens.proId === "" || this.BlocosHomeItens.proId === null) {
                    this.BlocosHomeItens.proId = 0;
                }
                else {
                    this.BlocosHomeItens.proId = parseInt(this.BlocosHomeItens.proId);
                }

                if (this.BlocosHomeItens.hbiVisivel === "" || this.BlocosHomeItens.hbiVisivel === null) {
                    this.BlocosHomeItens.hbiVisivel = true;
                }

                axios.post(`/Process/HomBlocosHomeItens/Add`, this.BlocosHomeItens)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaItens(this.BlocosHomeItens.hbhId);
                                this.LimpaInputimg();
                            }
                        });
            }
        },
        CarregaItens(value){
            const param1 = value;
            axios.get(`/Process/HomBlocosHomeItens/GetAllByHbhId/${param1}`)
                .then(response => {
                    this.ListaBlocosHomeItens = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.hbiOrdem) || 0;
                                                                                const valorB = parseInt(b.hbiOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });                   
                });
        },
        LimpaInputimg(){
            this.BlocosHomeItens.hbiId = 0;
            this.BlocosHomeItens.proId = '';
            this.BlocosHomeItens.hbiTexto = '';
            this.BlocosHomeItens.hbiLink = '';
            this.BlocosHomeItens.hbiVisivel = '';
            this.BlocosHomeItens.hbiOrdem = '';
            this.BlocosHomeItens.hbiUrlImg = '';
            this.selectedFile = null;
        },
        SaveImages(imgs){
            var _imginfo = imgs;
            if (_imginfo.hbiOrdem === "" || _imginfo.hbiOrdem === null) {
                _imginfo.hbiOrdem = 0;
            }
            else {
                _imginfo.hbiOrdem = parseInt(_imginfo.hbiOrdem);
            }
            
            if (_imginfo.proId === "" || _imginfo.proId === null) {
                _imginfo.proId = 0;
                }
                else {
                    _imginfo.proId = parseInt(_imginfo.proId);
                }

                if (_imginfo.hbiVisivel === "" || _imginfo.hbiVisivel === null) {
                    _imginfo.hbiVisivel = true;
                }
            axios.put(`/Process/HomBlocosHomeItens/Update`, _imginfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaItens(_imginfo.hbhId);
                        }
                    });
        },
        DeleteImages(imgs){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = imgs.hbiId;
                axios.delete(`/Process/HomBlocosHomeItens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaItens(imgs.hbhId);
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.BlocosHome.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/HomBlocosHome/GetAll`)
                        .then(response => {
                            this.ListBlocosHomeGeral = response.data; 
                            if (this.BlocosHome.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });

                    axios.get(`/Process/HomTipoBloco/GetAll`)
                    .then(response => {
                        this.TiposBlocosHome = response.data;
                    });

                    this.montaGrid();
                    this.BlocosHome.cdtId = 30;
                    this.BlocosHome.grtId = 8;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
