<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="DataEntrega" label="Data Entrega" :items="dataEntregas" @update:modelValue="CarregaZonas(DataEntrega)"
                                                item-title="date" item-value="date" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="ZonaCidade" label="Zona" :items="ZonaCidades" @update:modelValue="CarregaSubZonas(ZonaCidade)"
                                                item-title="zonacid" item-value="zonacid" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="ZonaBairro" multiple persistent-hint label="SubZona" :items="ZonaBairros" @update:modelValue="FiltraZonasSubZonas(ZonaBairro)"
                                                item-title="subzonacid" item-value="subzonacid" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12" style="display:inline-flex; text-align: center;justify-content: center;">
                                                <button @click.prevent="gerarPDF" class="btn btn-info" style="margin-right: 20px;">Gerar PDF</button>
                                                <download-excel   @click="gerarExcel()" class="btn btn-success" :data = "rowsexl"  worksheet="Entregas" type="xlsx" :name="`Entregas-${DataEntrega}-${ZonaCidade}-${ZonaBairro}.xlsx`">Gerar Excel</download-excel>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </div>
                        <div class="faq-wrap">
                            <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                                required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div ref="tabela" class="sua-tabela-classe">
                                    <div style="padding-top: 10px;padding-bottom: 10px;font-weight: 600;"><center>Data Entrega: {{ DataEntrega }} - Zona: {{ ZonaCidade }} - SubZona  <span v-for="(bairro, index) in ZonaBairro" :key="index">
            {{ index ? ', ' : '' }}{{ bairro }}
        </span></center></div>
                                <easy-data-table ref="tabelaeasy" :headers="headers" :items="ListPedidosGeralFiltro" v-model="itemsSelected" alternating :rows-per-page="100"
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-diaEntrega="item">
                                        <span> {{ item.diaEntrega !== null ? formattedDate(item.diaEntrega) : null }}</span>
                                    </template>
                                    <template #item-ordem="item">
                                        <input type="number" v-model.number="item.ordem"  @input="updateOrdem(item, item.ordem)" style="width: 60px;height: 30px;border: 1px solid #cccccc;background-color: #ffffff;font-size: 15px;" />
                                    </template>
                                    <template #item-baiNome="item">
                                        <span> {{ item.baiNome == null ? item.pdcBaiNome : item.baiNome }}</span>
                                    </template>
                                </easy-data-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JsonExcel from "vue-json-excel3";

export default {
    name: 'PedidosZonaView',
    components: {
        EasyDataTable,
        moment,
        downloadExcel:JsonExcel
    },
    data: () => ({
        searchValue: '',
        NomeTela:'Pedidos por Zona',
        InfoTela:'Pedidos por Zona',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 35, cdtNome: "Pedidos por Zona" }],
        Pedidos:[],
        ListPedidosGeral:[],
        ListPedidosGeralFiltro:[],
        //
        dataEntregas: [],
        DataEntrega: '',
        ZonaCidades: [],
        ZonaCidade: '',
        ZonaBairros:[],
        ZonaBairro:[],
        //
        headersexl: [], // Defina seus cabeçalhos aqui
        rowsexl: [], // Defina seus dados de linha aqui
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Ordem", value: "ordem", sortable: true });
            this.headers.push({ text: "Id", value: "pedId", sortable: true, });
            this.headers.push({ text: "Entrega", value: "diaEntrega", sortable: true, });
            this.headers.push({ text: "Hora", value: "horaEntrega", sortable: true, });
            this.headers.push({ text: "Zona", value: "cidZona", sortable: true, });
            this.headers.push({ text: "SubZona", value: "baiZona", sortable: true, });
            this.headers.push({ text: "Nome", value: "cliNome", sortable: true, });
            this.headers.push({ text: "Endereço", value: "pdcEndereco", sortable: true, });
            this.headers.push({ text: "N", value: "pdcNumero", sortable: true, });
            this.headers.push({ text: "Compl", value: "pdcComplemento", sortable: true, });
            this.headers.push({ text: "Cidade", value: "cidNome", sortable: true, });
            this.headers.push({ text: "Bairro", value: "baiNome", sortable: true, });

        },
        CarregaZonas(dtentrega){
            this.ZonaCidades = [];
            this.ZonaCidade = '';
            this.ZonaBairros = [];
            this.ZonaBairro = [];

            this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x => this.$moment(x.diaEntrega).isSame(this.$moment(dtentrega, 'DD/MM/YYYY'), 'day'));

            // Criar um conjunto para armazenar datas únicas
            let ZonaSet = new Set();

                // Iterar sobre os pedidos e adicionar as datas únicas ao conjunto
                this.ListPedidosGeralFiltro.forEach(pedido => {
                    // Obter a data de entrega do pedido
                    let zonacid = pedido.cidZona;
                    
                    // Adicionar a data de entrega formatada ao conjunto
                    ZonaSet.add(zonacid);
                });

                // Converter o conjunto de datas únicas de volta para um array e ordenar
                this.ZonaCidades = Array.from(ZonaSet).sort();
        },
        CarregaSubZonas(zCidade){
            this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x => this.$moment(x.diaEntrega).isSame(this.$moment(this.DataEntrega, 'DD/MM/YYYY'), 'day') && x.cidZona == zCidade);
            this.ZonaBairros = [];
            this.ZonaBairro = [];
            // Criar um conjunto para armazenar datas únicas
            let SubZonaSet = new Set();

                // Iterar sobre os pedidos e adicionar as datas únicas ao conjunto
                this.ListPedidosGeralFiltro.forEach(pedido => {
                    // Obter a data de entrega do pedido
                    let subzonacid = pedido.baiZona;
                    
                    // Adicionar a data de entrega formatada ao conjunto
                    SubZonaSet.add(subzonacid);
                });

                // Converter o conjunto de datas únicas de volta para um array e ordenar
                this.ZonaBairros = Array.from(SubZonaSet).sort();
        },
        FiltraZonasSubZonas(zBairro){
            this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x => this.$moment(x.diaEntrega).isSame(this.$moment(this.DataEntrega, 'DD/MM/YYYY'), 'day') && x.cidZona == this.ZonaCidade && zBairro.includes(x.baiZona));
        },
        async gerarPDF() {
            await this.$nextTick();
            const pdf = new jsPDF({
                orientation: 'portrait', // Retrato (p)
                unit: 'mm', // Unidade de medida
                format: 'a4', // Formato de página A4
            });

            const tabela = this.$refs.tabela; // Substitua 'tabela' pelo ref correto da sua tabela

            // Capture a tabela como uma imagem
            const canvas = await html2canvas(tabela);

            // Calcule a largura e altura da página A4 em mm
            const larguraA4 = 210;
            const alturaA4 = 297;

           // Defina as margens do PDF
            const margem = 5;

        // Calcule a largura disponível levando em consideração as margens
        const larguraDisponivel = larguraA4 - (2 * margem);

        // Calcule a escala para ajustar o conteúdo à largura disponível
        const escala = larguraDisponivel / canvas.width;

        // Defina as dimensões da imagem da tabela com base na escala
        const larguraImagem = canvas.width * escala;
        const alturaImagem = canvas.height * escala;

        // Calcule a posição horizontal para centralizar a imagem dentro da largura disponível
        const posicaoHorizontal = (larguraA4 - larguraImagem) / 2;

        // Adicione a imagem ao PDF
        pdf.addImage(canvas, 'PNG', posicaoHorizontal, margem, larguraImagem, alturaImagem);

             // Imprima o PDF diretamente
             //pdf.autoPrint();

             //pdf.autoPrint({ variant: 'non-conform' });
             window.open(pdf.output('bloburl'), '_blank');
            // Salve ou exiba o PDF
            //pdf.save(`Pedido_${this.PedidosCli.pedId}.pdf`);
        },
       /* gerarExcel() {
            this.$nextTick(() => {
                this.rowsexl = [];
                const tabela = this.$refs.tabelaeasy.$el;
                // Extrai os dados da tabela
                // Obtém os cabeçalhos da tabela
                const tableHeaders = tabela.querySelectorAll('thead th span span');
                tableHeaders.forEach(header => {
                   this.headersexl.push(header.textContent);
                });
                // Obtém os dados da tabela
                const tableRows = tabela.querySelectorAll('tbody tr');
                tableRows.forEach(row => {
                    const rowData = {};
                    const cells = row.querySelectorAll('td');
                    cells.forEach((cell, index) => {
                        rowData[this.headersexl[index]] = cell.textContent.trim();
                    });
                    this.rowsexl.push(rowData);
                    });
                });
        },*/
        gerarExcel() {
    this.$nextTick(() => {
        this.rowsexl = [];
        const tabela = this.$refs.tabelaeasy.$el;

        // Obtém os cabeçalhos da tabela
        const tableHeaders = tabela.querySelectorAll('thead th span span');
        tableHeaders.forEach(header => {
            this.headersexl.push(header.textContent);
        });

        // Obtém os dados da tabela
        const tableRows = tabela.querySelectorAll('tbody tr');
        tableRows.forEach(row => {
            const rowData = {};
            const cells = row.querySelectorAll('td');
            cells.forEach((cell, index) => {
                // Verifica se o último elemento da linha é um input
                if (cell.querySelector('input')) {
                    rowData[this.headersexl[index]] = cell.querySelector('input').value.trim();
                } else {
                    rowData[this.headersexl[index]] = cell.textContent.trim();
                }
            });
            this.rowsexl.push(rowData);
        });
    });
},
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                   axios.get(`/Process/PedPedidos/GetAllEntregaZona`)
                        .then(response => {
                            this.ListPedidosGeral = response.data; 
                          
                            this.ListPedidosGeralFiltro = this.ListPedidosGeral.map(item => ({
                                ...item,
                                ordem: null // Inicialmente vazio ou com valor padrão
                            })).sort((a, b) => {
                                let dateA = new Date(this.$moment(a.diaEntrega, 'DD/MM/YYYY'));
                                let dateB = new Date(this.$moment(b.diaEntrega, 'DD/MM/YYYY'));
                                return dateA - dateB;
                            });
                          
                            console.log('this.ListPedidosGeralFiltro')   
                            console.log(this.ListPedidosGeralFiltro) 

                          // Criar um conjunto para armazenar datas únicas
                            let dataEntregasSet = new Set();

                            // Iterar sobre os pedidos e adicionar as datas únicas ao conjunto
                            this.ListPedidosGeral.forEach(pedido => {
                                // Obter a data de entrega do pedido
                                let diaEntrega = pedido.diaEntrega;
                                
                                // Adicionar a data de entrega formatada ao conjunto
                                dataEntregasSet.add(this.formattedDate(diaEntrega));
                            });

                            // Converter o conjunto de datas únicas de volta para um array e ordenar
                            this.dataEntregas = Array.from(dataEntregasSet).sort((a, b) => {
                                // Converter as strings de data para objetos Date
                                let dateA = new Date(this.$moment(a, 'DD/MM/YYYY'));
                                let dateB = new Date(this.$moment(b, 'DD/MM/YYYY'));
                                
                                // Comparar as datas
                                return dateA - dateB;
                            });
                        });

                        this.montaGrid();
                                        this.Pedidos.cdtId = 35;
                                        this.Pedidos.grtId = 7;
                });
        },
        updateOrdem(index, newValue) {
            console.log(index)
            console.log(newValue)
        this.ListPedidosGeralFiltro.filter(x=> x.pedId == index.pedId)[0].ordem = newValue;
    }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
