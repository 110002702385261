<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.eftId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Entregas.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(Entregas.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3" v-if="Paises.length > 0">
                                            <v-select v-model="Entregas.cpaId" label="Paises" :items="Paises" @update:modelValue="CarregaEstados(Entregas.cpaId)"
                                                item-title="cpaNome" item-value="cpaId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.cetId" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(Entregas.cetId)"
                                                item-title="cetNome" item-value="cetId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.cidId" label="Cidades" :items="Cidades" @update:modelValue="CarregaBairros(Entregas.cidId)"
                                                item-title="cidNome" item-value="cidId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.baiId" label="Bairros" :items="Bairros"
                                                item-title="baiNome" item-value="baiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.eftTipoEntrega"
                                                label="Tipo Entrega" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.eftStatus" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="eftStatus" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.eftValorFrete" type="number" prefix="R$"  
                                             @change="Entregas.eftValorFrete != null ? (Entregas.eftValorFrete = parseFloat(Entregas.eftValorFrete,10)) : (Entregas.eftValorFrete = 0.0)"
                                                label="Valor" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Entregas.eftAtrasoHs" type="number"
                                                label="Atraso Hs" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.eftCompraMinima" type="number" prefix="R$"  
                                             @change="Entregas.eftCompraMinima != null ? (Entregas.eftCompraMinima = parseFloat(Entregas.eftCompraMinima,10)) : (Entregas.eftCompraMinima = 0.0)"
                                                label="Compra Mínima" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.eftPrazoDias" type="number"
                                                label="Prazo Dias" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Entregas.eftId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListEntregas" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-eftStatus="item">
                            <span>{{ item.eftStatus ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-eftValorFrete="item">
                            {{ item.eftValorFrete !== null ? formatarValor(item.eftValorFrete) : 0 }}
                        </template>
                        <template #item-eftCompraMinima="item">
                            {{ item.eftCompraMinima !== null ? formatarValor(item.eftCompraMinima) : 0 }}
                        </template>
                        
                        <template #item-cetId="item">
                        <span>{{ item.NomeEstado || 'Todos' }}</span>
                        </template>
                        <template #item-cidId="item">
                        <span>{{ item.NomeCidade || 'Todos' }}</span>
                        </template>
                        <template #item-baiId="item">
                        <span>{{ item.NomeBairro || 'Todos' }}</span>
                        </template>


                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'EntregasFreteView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Entregas Frete',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 22, cdtNome: "Entregas Frete" }],
        //dados
        Entregas: {
            eftId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            cpaId:'',
            cetId:'',
            cidId:'',
            baiId:'',
            eftTipoEntrega: '',
            eftAtrasoHs: '',
            eftStatus: '',
            eftValorFrete: '',
            eftPrazoDias: '',
            eftCompraMinima:'',
        },
        ListEntregas: [],
        StatusAtivo: [{ nome: "Sim", eftStatus: true }, { nome: "Não", eftStatus: false }],
        //dados
        ListEntregasGeral:[],
        Bairros: [],
        Cidades: [],
        Estados: [],
        Paises: [],
        EstadosTodos:[],
        CidadesTodos:[],
        BairrosTodos:[],
        //
        estadosMap: {},
        cidadesMap: {},
        bairrosMap: {}
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListEntregas = this.ListEntregasGeral.filter(x=> x.idiId == this.Entregas.idiId);
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cetNome || ""; 
                                                                                    const nomeB = b.cetNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                    if (this.Estados.filter(x=> x.cetId == this.Entregas.cetId).length == 0){
                        this.Entregas.cetId = '';
                        this.Entregas.cidId = '';
                        this.Entregas.baiId = '';
                    }

                });
        },
        CarregaCidades(value) {
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                    if (this.Cidades.filter(x=> x.cidId == this.Entregas.cidId).length == 0){
                        this.Entregas.cidId = '';
                        this.Entregas.baiId = '';
                    }
                });
        },
        CarregaBairros(value) {
            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                                                                                    const nomeA = a.baiNome || ""; 
                                                                                    const nomeB = b.baiNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                    if (this.Bairros.filter(x=> x.baiId == this.Entregas.baiId).length == 0){
                        this.Entregas.baiId = '';
                    }

                });
        },
        Limpar() {
            this.Entregas.eftId=0;
            this.Entregas.grtId=0;
            this.Entregas.cdtId=0;
            this.Entregas.cpaId="";
            this.Entregas.cetId="";
            this.Entregas.cidId="";
            this.Entregas.baiId="";
            this.Entregas.eftTipoEntrega="";
            this.Entregas.eftAtrasoHs="";
            this.Entregas.eftStatus="";
            this.Entregas.eftValorFrete="";
            this.Entregas.eftPrazoDias="";
            this.Entregas.eftCompraMinima="";
            this.Bairros = [];
            this.Cidades = [];
            this.Estados = [];
            this.tab = 'dados';
            this.Get();
        },
        Add() {
                if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.cpaId === '') {
                    this.$mensagemAviso("Campo País Obrigatório.")
                }
                else if (this.Entregas.eftTipoEntrega === '') {
                    this.$mensagemAviso("Campo Tipo Entrega Obrigatório.")
                }
                else if (this.Entregas.eftStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else if (this.Entregas.eftValorFrete === '') {
                    this.$mensagemAviso("Campo Valor Obrigatório.")
                }
                else if (this.ListEntregasGeral.filter(x=> x.cetId == this.Entregas.cetId && x.cidId == this.Entregas.cidId && x.baiId == this.Entregas.baiId)[0]){
                    this.$mensagemAviso("Já Existe um Registro para essa Combinação de Estado, Cidade e Bairro.")
                }
                else {
                    if (this.Entregas.eftStatus === "" || this.Entregas.eftStatus === null) {
                        this.Entregas.eftStatus = true;
                    }
                    if (this.Entregas.eftAtrasoHs === "" || this.Entregas.eftAtrasoHs === null) {
                        this.Entregas.eftAtrasoHs = 0;
                    }
                    else{
                        this.Entregas.eftAtrasoHs = parseInt(this.Entregas.eftAtrasoHs);
                    }
                    if (this.Entregas.eftPrazoDias === "" || this.Entregas.eftPrazoDias === null) {
                        this.Entregas.eftPrazoDias = 0;
                    }
                    else{
                        this.Entregas.eftPrazoDias = parseInt(this.Entregas.eftPrazoDias);
                    }
                   
                    if (this.Entregas.eftCompraMinima === "" || this.Entregas.eftCompraMinima === null) {
                        this.Entregas.eftCompraMinima = 0;
                    }
                    if (this.Entregas.cetId === "" || this.Entregas.cetId === null) {
                        this.Entregas.cetId = 0;
                    }

                    if (this.Entregas.cidId === "" || this.Entregas.cidId === null) {
                        this.Entregas.cidId = 0;
                    }

                    if (this.Entregas.baiId === "" || this.Entregas.baiId === null) {
                        this.Entregas.baiId = 0;
                    }
                                                 
                    axios.post(`/Process/EntEntregaFrete/Add`, this.Entregas)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Entregas.eftId = response.data;
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.Get();
                                    this.Limpar();
                                    this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.eftTipoEntrega === '') {
                    this.$mensagemAviso("Campo Tipo Entrega Obrigatório.")
                }
                else if (this.Entregas.eftStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else if (this.Entregas.eftValorFrete === '') {
                    this.$mensagemAviso("Campo Valor Obrigatório.")
                }
                else {
                    if (this.Entregas.eftStatus === "" || this.Entregas.eftStatus === null) {
                        this.Entregas.eftStatus = true;
                    }
                    if (this.Entregas.eftAtrasoHs === "" || this.Entregas.eftAtrasoHs === null) {
                        this.Entregas.eftAtrasoHs = 0;
                    }
                    else{
                        this.Entregas.eftAtrasoHs = parseInt(this.Entregas.eftAtrasoHs);
                    }
                    if (this.Entregas.eftPrazoDias === "" || this.Entregas.eftPrazoDias === null) {
                        this.Entregas.eftPrazoDias = 0;
                    }
                    else{
                        this.Entregas.eftPrazoDias = parseInt(this.Entregas.eftPrazoDias);
                    }

                    if (this.Entregas.eftCompraMinima === "" || this.Entregas.eftCompraMinima === null) {
                        this.Entregas.eftCompraMinima = 0;
                    }
                    
                    if (this.Entregas.cetId === "" || this.Entregas.cetId === null) {
                        this.Entregas.cetId = 0;
                    }

                    if (this.Entregas.cidId === "" || this.Entregas.cidId === null) {
                        this.Entregas.cidId = 0;
                    }

                    if (this.Entregas.baiId === "" || this.Entregas.baiId === null) {
                        this.Entregas.baiId = 0;
                    }
             
                axios.put(`/Process/EntEntregaFrete/Update`, this.Entregas)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.eftId;
                axios.delete(`/Process/EntEntregaFrete/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        async EditGrid(item) {
            this.tab = 'dados';
            this.Entregas = item;
            this.Entregas.cidId = item.cidId
            this.Entregas.cetId = item.cetId
            this.Entregas.cpaId = item.cpaId
            this.Entregas.baiId = item.baiId
            await this.CarregaEstados(item.cpaId)
            await this.CarregaCidades(item.cetId)
            await this.CarregaBairros(item.cidId)
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "eftId" });
            this.headers.push({ text: "Estado", value: "cetId" });
            this.headers.push({ text: "Cidade", value: "cidId" });
            this.headers.push({ text: "Bairro", value: "baiId" });
            this.headers.push({ text: "Tipo", value: "eftTipoEntrega" });
            this.headers.push({ text: "Status", value: "eftStatus" }); 
            this.headers.push({ text: "Valor", value: "eftValorFrete" }); 
            this.headers.push({ text: "Atraso Hs", value: "eftAtrasoHs" }); 
            this.headers.push({ text: "Mínimo", value: "eftCompraMinima" }); 
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
    async Get() {

                      await axios.get(`/Process/CadEstados/GetAll`)
                            .then(response => {
                                this.EstadosTodos = response.data;
                                    
                                this.EstadosTodos.forEach(estado => {
                                    this.estadosMap[estado.cetId] = estado.cetNome;
                                });
                            });

                            await axios.get(`/Process/CadCidades/GetAll`)
                            .then(response => {
                                this.CidadesTodos = response.data;

                                this.CidadesTodos.forEach(cidade => {
                                    this.cidadesMap[cidade.cidId] = cidade.cidNome;
                                });
                            });

                            await   axios.get(`/Process/CadBairros/GetAll`)
                        .then(response => {
                            this.BairrosTodos = response.data;

                            this.BairrosTodos.forEach(bairro => {
                                this.bairrosMap[bairro.baiId] = bairro.baiNome;
                            });
                        });            

                        await   axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Entregas.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }

                 

                    axios.get(`/Process/EntEntregaFrete/GetAll`)
                        .then(response => {
                            this.ListEntregasGeral = response.data; 
                            if (this.Entregas.idiId != ''){
                                this.ListEntregasGeral.forEach(entrega => {
                                // Nome do estado
                                entrega.NomeEstado = this.estadosMap[entrega.cetId] || null;
                                
                                // Nome da cidade
                                entrega.NomeCidade = this.cidadesMap[entrega.cidId] || null;
                                
                                // Nome do bairro
                                entrega.NomeBairro = this.bairrosMap[entrega.baiId] || null;
                                });

                               console.log('this.ListEntregasGeral')
                               console.log(this.ListEntregasGeral)
                                this.CarregaLista();
                            }                           
                        });

                     

                    this.montaGrid();
                    this.Entregas.cdtId = 22;
                    this.Entregas.grtId = 5;

                });
        }
    },
    created() {
        this.Get();
        axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.filter(x => x.cpaVisivel).sort((a, b) => {
                                                                                    const nomeA = a.cpaNome || ""; 
                                                                                    const nomeB = b.cpaNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                  if (this.Paises.length == 1){
                    this.Entregas.cpaId = this.Paises[0].cpaId;
                    this.CarregaEstados(this.Entregas.cpaId)
                  }
                });
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
