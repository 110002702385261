<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.excId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Entregas.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(Entregas.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="DataIni" type="date"
                                                label="Data Inicial" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="DataFim" type="date"
                                                label="Data Final" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.excStatus" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="excStatus" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Paises.length > 0">
                                            <v-select v-model="Entregas.cpaId" label="Paises" :items="Paises" @update:modelValue="CarregaEstados(Entregas.cpaId)"
                                                item-title="cpaNome" item-value="cpaId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.cetId" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(Entregas.cetId)"
                                                item-title="cetNome" item-value="cetId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.cidId" label="Cidades" :items="Cidades" @update:modelValue="CarregaBairros(Entregas.cidId)"
                                                item-title="cidNome" item-value="cidId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.baiId" label="Bairros" :items="Bairros" clearable
                                                item-title="baiNome" item-value="baiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.excHoraIni" label="Hora Inicial" :items="StatusHora"
                                                item-title="nome" item-value="valor" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.excMinIni" label="Min Inicial" :items="StatusMin"
                                                item-title="nome" item-value="valor" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.excHoraFim" label="Hora Final" :items="StatusHora"
                                                item-title="nome" item-value="valor" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.excMinFim" label="Min Final" :items="StatusMin"
                                                item-title="nome" item-value="valor" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.excQtde" type="number"
                                                label="Quantidade" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.excAtrasoHs" label="Bloquear Venda Geral" :items="[{ nome: 'Sim', excAtrasoHs: 1 }, { nome: 'Não', excAtrasoHs: 0 }]"
                                                item-title="nome" item-value="excAtrasoHs" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Entregas.excCategoria" label="Venda Somente para " :items="ListaHierarquiaSeg"
                                                item-title="psgNomeSegmento" item-value="psgId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="9">
                                            <v-text-field v-model="Entregas.excObs" type="text"
                                                label="Observação" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Entregas.excId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListEntregas" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-excStatus="item">
                            <span>{{ item.excStatus ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-excMinIni="item">
                            <span>{{ item.excMinIni == 0 ? '00' : item.excMinIni }}</span>
                        </template>
                        <template #item-excMinFim="item">
                            <span>{{ item.excMinFim == 0 ? '00' : item.excMinFim }}</span>
                        </template>
                        <template #item-excDataExcecao="item">
                            <span>{{ formattedDate(item.excDataExcecao) }}</span>
                        </template>
                        <template #item-cetId="item">
                            <span v-if="EstadosTodos.filter(x=> x.cetId == item.cetId)[0]">
                                {{ EstadosTodos.filter(x=> x.cetId == item.cetId)[0].cetNome}}
                            </span>
                            <span v-else>Todos</span>
                        </template>
                        <template #item-cidId="item">
                            <span v-if="CidadesTodos.filter(x=> x.cidId == item.cidId)[0]">
                                {{ CidadesTodos.filter(x=> x.cidId == item.cidId)[0].cidNome }}
                            </span>
                            <span v-else>Todos</span>
                        </template>
                        <template #item-baiId="item">
                            <span v-if="BairrosTodos.filter(x => x.baiId === item.baiId)[0]">
                                {{ BairrosTodos.filter(x => x.baiId === item.baiId)[0].baiNome }}
                            </span>
                            <span v-else>Todos</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'EntregasExcecaoView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Entregas Exceção',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 24, cdtNome: "Entregas Excecao" }],
        //dados
        Entregas: {
            excId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            cpaId:'',
            cetId:'',
            cidId:'',
            baiId:'',
            excDataExcecao: '',
            excStatus: '',
            excQtde: '',
            excHoraIni: '',
            excHoraFim: '',
            excMinIni:'',
            excMinFim:'',
            excAtrasoHs: '',
            excCategoria:'',
            excObs:'',
        },
        ListEntregas: [],
        StatusAtivo: [{ nome: "Sim", excStatus: true }, { nome: "Não", excStatus: false }],
        StatusHora: [
            { nome: "0", valor: 0 },
            { nome: "1", valor: 1 },
            { nome: "2", valor: 2 },
            { nome: "3", valor: 3 },
            { nome: "4", valor: 4 },
            { nome: "5", valor: 5 },
            { nome: "6", valor: 6 },
            { nome: "7", valor: 7 },
            { nome: "8", valor: 8 },
            { nome: "9", valor: 9 },
            { nome: "10", valor: 10 },
            { nome: "11", valor: 11 },
            { nome: "12", valor: 12 },
            { nome: "13", valor: 13 },
            { nome: "14", valor: 14 },
            { nome: "15", valor: 15 },
            { nome: "16", valor: 16 },
            { nome: "17", valor: 17 },
            { nome: "18", valor: 18 },
            { nome: "19", valor: 19 },
            { nome: "20", valor: 20 },
            { nome: "21", valor: 21 },
            { nome: "22", valor: 22 },
            { nome: "23", valor: 23 },
        ],
        StatusMin: [
            { nome: "00", valor: 0 },
            { nome: "15", valor: 15 },
            { nome: "30", valor: 30 },
            { nome: "45", valor: 45 },
        ],
        //dados
        ListEntregasGeral:[],
        Bairros: [],
        Cidades: [],
        Estados: [],
        Paises: [],
        EstadosTodos:[],
        CidadesTodos:[],
        BairrosTodos:[],
        DataIni:'',
        DataFim:'',
        ListaHierarquiaSeg:[],
        ListaSegmentos:[],
        ListSegmentosProdGeral:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListEntregas = this.ListEntregasGeral.filter(x=> x.idiId == this.Entregas.idiId);
           console.log('this.ListEntregas')
           console.log(this.ListEntregas)
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cetNome || ""; 
                                                                                    const nomeB = b.cetNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                    if (this.Estados.filter(x=> x.cetId == this.Entregas.cetId).length == 0){
                        this.Entregas.cetId = '';
                        this.Entregas.cidId = '';
                        this.Entregas.baiId = '';
                    }

                });
        },
        CarregaCidades(value) {
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                    if (this.Cidades.filter(x=> x.cidId == this.Entregas.cidId).length == 0){
                        this.Entregas.cidId = '';
                        this.Entregas.baiId = '';
                    }
                });
        },
        CarregaBairros(value) {
            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                                                                                    const nomeA = a.baiNome || ""; 
                                                                                    const nomeB = b.baiNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                    if (this.Bairros.filter(x=> x.baiId == this.Entregas.baiId).length == 0){
                        this.Entregas.baiId = '';
                    }

                });
        },
        Limpar() {
            this.Entregas.excId=0;
            this.Entregas.grtId=0;
            this.Entregas.cdtId=0;
            this.Entregas.cpaId="";
            this.Entregas.cetId="";
            this.Entregas.cidId="";
            this.Entregas.baiId="";
            this.Entregas.excDataExcecao="";
            this.DataIni = "";
            this.DataFim = "";
            this.Entregas.excStatus="";
            this.Entregas.excQtde="";
            this.Entregas.excHoraIni="";
            this.Entregas.excHoraFim="";
            this.Entregas.excMinIni="";
            this.Entregas.excMinFim="";
            this.Entregas.excAtrasoHs="";
            this.Entregas.excCategoria="";
            this.Entregas.excObs="";
            this.Bairros = [];
            this.Cidades = [];
            this.Estados = [];
            this.tab = 'dados';
            this.Get();
        },
        Add() {
                if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.cpaId === '') {
                    this.$mensagemAviso("Campo País Obrigatório.")
                }
                else if (this.DataIni === '') {
                    this.$mensagemAviso("Campo Data Inicial Obrigatório.")
                }
                else if (this.DataFim === '') {
                    this.$mensagemAviso("Campo Data Final Obrigatório.")
                }
                else if (moment(this.DataFim).isSameOrBefore(this.DataIni, 'day')) {
                this.$mensagemAviso("A Data Final deve ser maior que a Data Inicial.");
                }
                else if (moment(this.DataIni).isBefore(moment(), 'day')) {
                    this.$mensagemAviso("A Data Inicial não pode ser menor que a data atual.");
                }
                else if (this.Entregas.excQtde === '') {
                    this.$mensagemAviso("Campo Quantidade Obrigatório.")
                }
                else if (this.Entregas.excHoraIni === '') {
                    this.$mensagemAviso("Campo Hora Inicial Obrigatório.")
                }
                else if (this.Entregas.excMinIni === '') {
                    this.$mensagemAviso("Campo Minuto Inicial Obrigatório.")
                }
                else if (this.Entregas.excHoraFim === '') {
                    this.$mensagemAviso("Campo Hora Final Obrigatório.")
                }
                else if (this.Entregas.excMinFim === '') {
                    this.$mensagemAviso("Campo Minuto Final Obrigatório.")
                }
                else if (this.Entregas.excStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else {
                    if (this.Entregas.excStatus === "" || this.Entregas.excStatus === null) {
                        this.Entregas.excStatus = true;
                    }
                    
                    if (this.Entregas.excQtde === "" || this.Entregas.excQtde === null) {
                        this.Entregas.excQtde = 0;
                    }
                    else{
                        this.Entregas.excQtde = parseInt(this.Entregas.excQtde);
                    }               

                    if (this.Entregas.cetId === "" || this.Entregas.cetId === null) {
                        this.Entregas.cetId = 0;
                    }

                    if (this.Entregas.cidId === "" || this.Entregas.cidId === null) {
                        this.Entregas.cidId = 0;
                    }

                    if (this.Entregas.baiId === "" || this.Entregas.baiId === null) {
                        this.Entregas.baiId = 0;
                    }

                    if (this.Entregas.excAtrasoHs === "" || this.Entregas.excAtrasoHs === null) {
                        this.Entregas.excAtrasoHs = 0;
                    }
                    else{
                        this.Entregas.excAtrasoHs = parseInt(this.Entregas.excAtrasoHs,10)
                    }

                    if (this.Entregas.excCategoria === "" || this.Entregas.excCategoria === null) {
                        this.Entregas.excCategoria = 0;
                    }
                    else{
                        this.Entregas.excCategoria = parseInt(this.Entregas.excCategoria,10)
                    }

                    console.log('this.Entregas')
                    console.log(this.Entregas)
    
                    // Converte as datas para objetos Moment
                    const startDate = moment(this.DataIni);
                    const endDate = moment(this.DataFim);

                    // Itera sobre as datas e adiciona ao array
                    for (let currentDate = startDate; currentDate.isSameOrBefore(endDate); currentDate.add(1, 'day')) {
                    // Clone o objeto this.Entregas para que possamos modificar apenas a data
                    const clonedEntregas = { ...this.Entregas };
                    
                    // Configura a data no objeto clonado
                    clonedEntregas.excDataExcecao = currentDate.toDate(); // Converte Moment para Date

                    // Realiza a chamada Axios com o objeto clonado
                    axios.post(`/Process/EntEntregaExcecao/Add`, clonedEntregas)
                        .then(response => {
                        if (response.data > 0) {
                            // Faça o que for necessário com a resposta (opcional)
                        }
                        })
                        .catch(error => {
                        console.error("Erro ao processar a data:", error);
                        // Trate o erro conforme necessário
                        });
                    }

                    // Após o loop, exiba a mensagem, chame o método Get() e Limpar()
                    this.$mensagemAvisoSucesso("Registros Incluídos.");
                    this.Get();
                    this.Limpar();
                    this.tab = 'dados';

                        
                   
            }
        },
        Put() {
            if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.cpaId === '') {
                    this.$mensagemAviso("Campo País Obrigatório.")
                }
                else if (this.DataIni === '') {
                    this.$mensagemAviso("Campo Data Inicial Obrigatório.")
                }
                else if (this.DataFim === '') {
                    this.$mensagemAviso("Campo Data Final Obrigatório.")
                }
                else if (moment(this.DataFim).isBefore(this.DataIni, 'day')) {
                this.$mensagemAviso("A Data Final deve ser maior que a Data Inicial.");
                }
                else if (this.Entregas.excQtde === '') {
                    this.$mensagemAviso("Campo Quantidade Obrigatório.")
                }
                else if (this.Entregas.excHoraIni === '') {
                    this.$mensagemAviso("Campo Hora Inicial Obrigatório.")
                }
                else if (this.Entregas.excMinIni === '') {
                    this.$mensagemAviso("Campo Minuto Inicial Obrigatório.")
                }
                else if (this.Entregas.excHoraFim === '') {
                    this.$mensagemAviso("Campo Hora Final Obrigatório.")
                }
                else if (this.Entregas.excMinFim === '') {
                    this.$mensagemAviso("Campo Minuto Final Obrigatório.")
                }
                else if (this.Entregas.excStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else {
                    if (this.Entregas.excStatus === "" || this.Entregas.excStatus === null) {
                        this.Entregas.excStatus = true;
                    }
                    
                    if (this.Entregas.excQtde === "" || this.Entregas.excQtde === null) {
                        this.Entregas.excQtde = 0;
                    }
                    else{
                        this.Entregas.excQtde = parseInt(this.Entregas.excQtde);
                    }               

                    if (this.Entregas.cetId === "" || this.Entregas.cetId === null) {
                        this.Entregas.cetId = 0;
                    }

                    if (this.Entregas.cidId === "" || this.Entregas.cidId === null) {
                        this.Entregas.cidId = 0;
                    }

                    if (this.Entregas.baiId === "" || this.Entregas.baiId === null) {
                        this.Entregas.baiId = 0;
                    }

                    if (this.Entregas.excAtrasoHs === "" || this.Entregas.excAtrasoHs === null) {
                        this.Entregas.excAtrasoHs = 0;
                    }
                    else{
                        this.Entregas.excAtrasoHs = parseInt(this.Entregas.excAtrasoHs,10)
                    }

                    if (this.Entregas.excCategoria === "" || this.Entregas.excCategoria === null) {
                        this.Entregas.excCategoria = 0;
                    }
                    else{
                        this.Entregas.excCategoria = parseInt(this.Entregas.excCategoria,10)
                    }

                    this.Entregas.excDataExcecao = moment(this.DataIni);
             
                axios.put(`/Process/EntEntregaExcecao/Update`, this.Entregas)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.excId;
                axios.delete(`/Process/EntEntregaExcecao/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        async EditGrid(item) {
            this.tab = 'dados';
            this.Entregas = item;
            this.Entregas.cidId = item.cidId
            this.Entregas.cetId = item.cetId
            this.Entregas.cpaId = item.cpaId
            this.Entregas.baiId = item.baiId
            if (item.excCategoria == 0){
                this.Entregas.excCategoria = "";
            }
            else{
                this.Entregas.excCategoria = item.excCategoria
            }
            
            this.DataIni = this.$moment(item.excDataExcecao).format('YYYY-MM-DD');
            this.DataFim = this.$moment(item.excDataExcecao).format('YYYY-MM-DD');
            await this.CarregaEstados(item.cpaId)
            await this.CarregaCidades(item.cetId)
            await this.CarregaBairros(item.cidId)
            //this.Entregas.excDataExcecao = this.$moment(this.Entregas.excDataExcecao).format('YYYY-MM-DD')
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "excId" });
            this.headers.push({ text: "Data", value: "excDataExcecao" }); 
            this.headers.push({ text: "Ativo", value: "excStatus" }); 
            this.headers.push({ text: "Estado", value: "cetId" });
            this.headers.push({ text: "Cidade", value: "cidId" });
            this.headers.push({ text: "Bairro", value: "baiId" });
            this.headers.push({ text: "Hora Ini", value: "excHoraIni" });
            this.headers.push({ text: "Min Ini", value: "excMinIni" }); 
            this.headers.push({ text: "Hora Fim", value: "excHoraFim" }); 
            this.headers.push({ text: "Min Fim", value: "excMinIni" }); 
            this.headers.push({ text: "Qtde", value: "excQtde" }); 
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        CarregaListaSegmentos(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.ListaHierarquiaSeg = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel && x.idiId == 1);
        //------ carrega select psgIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaHierarquiaSeg.filter(x=> x.psgIdPai== 0)) {
            segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaHierarquiaSeg, segmento.psgId, segmento.psgNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaHierarquiaSeg = segmentosComCaminho;   
            console.log('this.ListaHierarquiaSeg')
            console.log(this.ListaHierarquiaSeg)

        //------
        });
    },
       Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Entregas.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/EntEntregaExcecao/GetAll`)
                        .then(response => {
                            this.ListEntregasGeral = response.data; 
                            console.log('this.ListEntregasGeral123')
                             console.log(this.ListEntregasGeral)
                            if (this.Entregas.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });

                        axios.get(`/Process/CadEstados/GetAll`)
                            .then(response => {
                                this.EstadosTodos = response.data;
                            });

                        axios.get(`/Process/CadCidades/GetAll`)
                            .then(response => {
                                this.CidadesTodos = response.data;
                            });

                        axios.get(`/Process/CadBairros/GetAll`)
                        .then(response => {
                            this.BairrosTodos = response.data;
                        });


                    this.montaGrid();
                    this.Entregas.cdtId = 24;
                    this.Entregas.grtId = 5;

                });
        }
    },
    created() {
        this.Get();
        axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.filter(x => x.cpaVisivel).sort((a, b) => {
                                                                                    const nomeA = a.cpaNome || ""; 
                                                                                    const nomeB = b.cpaNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                  if (this.Paises.length == 1){
                    this.Entregas.cpaId = this.Paises[0].cpaId;
                    this.CarregaEstados(this.Entregas.cpaId)
                  }
                });


                this.CarregaListaSegmentos();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
